import {createActions} from '../../context'
import * as actions from './program.actions'

export const createProgram = store => ({
  programs: [],
  programsForSelect: [],
  programToEdit: undefined,
  currentTab: 'view',
  currentGlobalProgramId: undefined,
  globalProgram: undefined,
  currentCourses: [],
  possibleProgramCourses: [],
  providers: [],
  shopList: [],
  employeeList: [],
  registerList: [],
  actions: createActions(actions)(store),
})
