import * as queryString from 'query-string'
import * as flatten from 'flat'

import HttpService from '../../services/http.service'

const memberServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/member`
const memberSubscriptionEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipSubscription`
const memberPurchaseEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipPurchase`
const paymentBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/payment`
const refundAndCancelEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/refundAndCancel`
const reservationEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/reservation`
const customerEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/customer`
const upgradeMembershipServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/upgradeMembership`
const membershipsEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership`
const noshowChargeEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/noShowCharge`
const membershipServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership`
const billingPlanServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipBillingPlan`
const httpService = HttpService()
const MemberService = () => ({
  getMemberList: async memberSearch => {
    const searchQuery = queryString.stringify(
      flatten(memberSearch, {safe: true}),
      {arrayFormat: 'index'},
    )
    const {data} = await httpService.get(
      `${memberServiceEndpoint}?${searchQuery}`,
    )
    return data
  },

  getMemberById: async memberId => {
    const {data} = await httpService.get(`${memberServiceEndpoint}/${memberId}`)

    return data
  },

  checkIsNationalNetworkMember: async memberId => {
    const {data} = await httpService.get(`${memberServiceEndpoint}/validate/${memberId}`)

    return data
  },
  getMemberByEmail: async memberEmailInAffiliate => {
    const {data} = await httpService.post(`${customerEndpoint}/nonmembercustomerinfofromemail`, memberEmailInAffiliate)

    return data
  },

  getReceipts: async (memberId, subscriptionId, pagination, options) => {
    const url = `${memberSubscriptionEndpoint}/${memberId}/subscriptions/${subscriptionId}/invoices?pageSize=${pagination.pageSize}&pageNumber=${pagination.pageNumber}`
    const {data} = await httpService.get(url, options)

    return data
  },

  refund: async (receiptId, subscriptionId, options) => {
    const url = `${refundAndCancelEndpoint}/refund`
    const refundModel = {
      membershipSubscriptionId: subscriptionId,
      membershipSubscriptionInvoiceId: receiptId,
    }
    const {data} = await httpService.post(url, refundModel, options)

    return data
  },

  create: async memberToCreate => {
    const formData = new FormData()
    var {banner, logo, ...member} = memberToCreate

    formData.append('', JSON.stringify(member))
    formData.append('banner', banner)
    formData.append('logo', logo)

    const {data} = await httpService.post(
      `${memberServiceEndpoint}`,
      formData,

      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return data
  },

  update: async memberToUpdate => {
    const formData = new FormData()
    var {banner, logo, id, ...member} = memberToUpdate

    formData.append('', JSON.stringify(member))
    if (!!banner) {
      formData.append('banner', banner)
    }
    if (!!logo) {
      formData.append('logo', logo)
    }

    const {data} = await httpService.put(
      `${memberServiceEndpoint}/${id}`,
      formData,
    )

    return data
  },

  getMemberships: async memberId => {
    const {data} = await httpService.get(
      `${memberSubscriptionEndpoint}/${memberId}/subscriptions/`,
    )

    return data
  },
  getMembershipsHistory: async (memberId, subscriptionId)  => {
    const {data} = await httpService.get(
      `${memberSubscriptionEndpoint}/${memberId}/subscriptions/${subscriptionId}/History`,
    )

    return data
  },

  getCustomerDataMissing: async (memberId,programId)  => {
    const {data} = await httpService.get(
      `${customerEndpoint}/customerinfomissing?memberId=${memberId}&programId=${programId}`,
    )

    return data
  },

  getMembershipDetail: async (memberId, subscriptionId) => {
    const {data} = await httpService.get(
      `${memberSubscriptionEndpoint}/${memberId}/subscriptions/${subscriptionId}`,
    )

    return data
  },

  getInitialInvoice: async (memberId, billingPlanId, sourceSubscriptionId, scheduleId) => {
    const operator = sourceSubscriptionId ? '&':'?'
    const scheduleIdQuery = scheduleId && scheduleId > 0 ? operator + 'scheduleId='+scheduleId : ''
    const {data} = await httpService.get(
      `${paymentBaseUrl}/${memberId}/initialInvoice/${billingPlanId}${sourceSubscriptionId ? `?sourceMembershipSubscriptionId=${sourceSubscriptionId}` : ''}${scheduleIdQuery}`
    )

    return data;
  },

  getUpgradeInvoice: async (memberId, sourceSubscriptionId, targetBillingPlan, scheduleId) => {
    const scheduleIdQuery = scheduleId > 0 ? '&scheduleId='+scheduleId : ''
    const {data} = await httpService.get(
      `${paymentBaseUrl}/${memberId}/membershipUpgradeInvoice?membershipSubscriptionId=${sourceSubscriptionId}&billingPlanId=${targetBillingPlan}${scheduleIdQuery}`
    )

    return data;
  },

  getSavedCards: async (memberId, subscriptionId) => {
    const {data} = await httpService.get(
      `${paymentBaseUrl}/${memberId}/creditcards?membershipSubscriptionId=${subscriptionId}`,
    )

    return data
  },
  getSavedCardsFromProgram: async (memberId, programId, courseId) => {
    let uri = (courseId !== undefined && courseId !== null) ? `${paymentBaseUrl}/${memberId}/creditcardsfromprogram/${programId}?courseId=${courseId}` : `${paymentBaseUrl}/${memberId}/creditcardsfromprogram/${programId}`
    const {data} = await httpService.get(uri)
    
    return data
  },

  addNewCreditCard: async (card, memberId, subscriptionId) => {
    const {data} = await httpService.post(
      `${paymentBaseUrl}/${memberId}/creditcards?membershipSubscriptionId=${subscriptionId}`,
      {
        ...card
      },
    )

    return data
  },
  
  addNewCreditCardForPurchase: async (card, memberId, programId, courseId) => {
    const {data} = await httpService.post(
      `${paymentBaseUrl}/${memberId}/creditcardsforpurchase?programId=${programId}&courseId=${courseId}`,
      {
        ...card
      },
    )

    return data
  },

  deleteCreditCard: async (creditCardId, memberId, subscriptionId) => {
    const {data} = await httpService.delete(
      `${paymentBaseUrl}/${memberId}/creditcards/${creditCardId}?membershipSubscriptionId=${subscriptionId}`,
    )

    return data
  },
  deleteCreditCardForPurchase: async (creditCardId, memberId, programId, courseId) => {
    const {data} = await httpService.delete(
      `${paymentBaseUrl}/${memberId}/creditcardsforpurchase/${creditCardId}?programId=${programId}&courseId=${courseId}`,
    )

    return data
  },
  updatePaymentMethod: async updateMembershipPaymentMethod => {
    const {data} = await httpService.put(
      `${memberPurchaseEndpoint}/updatePaymentMethod`,
      updateMembershipPaymentMethod,
    )

    return data
  },
  payLastFailedInvoice: async payLastFailedInvoiceMembership => {
    const {data} = await httpService.put(
      `${memberPurchaseEndpoint}/payLastFailedInvoice/${payLastFailedInvoiceMembership.userId}`,
      {
        lastInvoiceId: payLastFailedInvoiceMembership.lastInvoiceId,
        creditCardId:payLastFailedInvoiceMembership.creditCardId,
        membershipSubscriptionId:payLastFailedInvoiceMembership.membershipSubscriptionId,
      },
    )

    return data
  },
  
  getMembershipsById: async (programId, membershipId) => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/byid/${membershipId}?programId=${programId}`
    )

    return data
  },

  cancelMembershipSubscription: async (subscriptionId, cancellationType) => {
    await httpService.delete(
      `${refundAndCancelEndpoint}/${subscriptionId}?type=${cancellationType}`,
    )
  },

  getBooking: async (memberId, id) => {
    const {data} = await httpService.get(
      `${reservationEndpoint}/memberdetail/${id}?userId=${memberId}`,
    )

    return data
  },

  undoCancellationSubscription: async (userId, subscriptionId) => {
    await httpService.put(
      `${refundAndCancelEndpoint}/${userId}/subscriptions/${subscriptionId}/undo-cancellation`,
    )
  },

  cancelBooking: async reservationCancelModel => {
    await httpService.post(
      `${refundAndCancelEndpoint}/reservation`,
      reservationCancelModel,
    )
  },

  chargeNoShow: async noShowChargeModel => {
    const noShowChargePayment = noShowChargeModel.noShowChargePayment
    const {data} = await httpService.post(
      `${noshowChargeEndpoint}/pay`,
      noShowChargePayment,
    )
    return data
  },
  cancelAndRefundBooking: async reservationCancelModel => {
    await httpService.post(
      `${refundAndCancelEndpoint}/reservationrefund`,
      reservationCancelModel,
    )
  },

  getSuspendedDates: async (userId, subscriptionId, billingCycles) => {
    const {data} = await httpService.get(
      `${refundAndCancelEndpoint}/${userId}/subscriptions/${subscriptionId}/suspend?billingCycles=${billingCycles}`,
    )

    return data
  },

  suspendMembership: async (userId, subscriptionId, billingCycles) => {
    const {data} = await httpService.post(
      `${refundAndCancelEndpoint}/suspendMembership`,
      {
        membershipSubscriptionId: subscriptionId,
        userId,
        numberOfBillingCycles: billingCycles,
      },
    )

    return data
  },

  revokeSuspensionMembership: async (userId, subscriptionId) => {
    const {
      data,
    } = await httpService.post(
      `${refundAndCancelEndpoint}/revokeSuspendedMembership`,
      {userId, subscriptionId},
    )

    return data
  },

  getUserInformation: async (userId, clickedAddMember, programId) => {
    const url = clickedAddMember ? `${customerEndpoint}/nonmembercustomerinformation/${userId}?programId=${programId}` :`${customerEndpoint}/membercustomerinformation/${userId}`
    const {data} = await httpService.get(
      url,
    )
    return data
  },
  validateMembershipForPurchase: async (memberId, membershipId, billingPlanId, scheduleId) => {
    const { data } = await httpService.get(
      `${membershipsEndpoint}/${memberId}/ValidateMembershipForPurchase?membershipId=${membershipId}&billingPlanId=${billingPlanId}`,
    )
    return {data}
  },
  createUserInformation: async (newUserInformation, programId) => {
    const { data } = await httpService.post(`${customerEndpoint}/createUser?programId=${programId}`, {
      ...newUserInformation
    })
    return data
  },

  updateUserInformation: async (userId, newUserInformation, clickedAddMember, programId) => {
    const url = clickedAddMember ? `${customerEndpoint}/updateuserdetails/nonmember?programId=${programId}`: `${customerEndpoint}/updateuserdetails/member`
    await httpService.put(url, {
      userId,
      ...newUserInformation,
    })
  },
  updateCustomerId: async (memberId, subscriptionId,customerIdobj ) => {
    const url = `${memberSubscriptionEndpoint}/${memberId}/subscriptions/${subscriptionId}/CustomerId`
    await httpService.put(url, {
      ...customerIdobj,
    })
  },

  upgradeMembership: async (memberId, membershipSourceId, targetMembershipId, targetBillingPlanId, creditCardId) => {
    const {data} = await httpService.post(
      `${upgradeMembershipServiceEndpoint}`,
      {
        userId: memberId,
        sourceSubscriptionId: membershipSourceId,
        targetMembershipId,
        targetBillingPlanId,
        creditCardId
      })

    return data
  },

  purchaseMembership: async (
    memberId,
    membershipId,
    billingPlanId,
    creditCardId,
    selectedEmployeeId,    
    scheduleId
  ) => {


    const {data} = await httpService.post(`${memberPurchaseEndpoint}/${memberId}`, {
      membershipId: membershipId,
      billingPlanId: billingPlanId,
      creditCardId: creditCardId,
      referralEmployeeId: !selectedEmployeeId ? null : +selectedEmployeeId,
      scheduleId: scheduleId
    })

    return data
  },
  getTermsAndConditions: async (membershipId, options) => {
    const {data} = await httpService.get(
      `${membershipsEndpoint}/${membershipId}/termsAndConditions`,
      options,
    )

    return data
  },
  getTargetMembershipById: async (membershipId) => {
    const {data} = await httpService.get(
      `${membershipsEndpoint}/${membershipId}`
    )
    return data
  },

  getTargetMembershipAndBillingPlan: async (sourceMembershipId,isOneTimePass) => {
    const {data} = await httpService.get(
      `${membershipsEndpoint}/targetMembershipAndBillingPlans?sourceMembershipId=${sourceMembershipId}&isOneTimePass=${isOneTimePass}`
    )

    return data
  },

  getReceiptPdf: async (invoiceId, memberEmail, memberName) => {
    const {data} = await httpService.get(
      `${memberSubscriptionEndpoint}/invoice/${invoiceId}/receipt?email=${memberEmail}&name=${memberName}`
    )

    return data
  },

  getMembershipsByProgram: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/GetMembershipByProgram?programId=${programId}`,
    )

    return data
    }
  },

  getBillingPlansByProgram: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${billingPlanServiceEndpoint}?programId=${programId}`,
    )

    return data
    }
  },

  getProgramConfigForCustomerProfile: async (programId) => {
    const result = await httpService.get(`${customerEndpoint}/ProgramConfigForCustomerProfile?programId=${programId}`)
    if (result) {
      return result.data
    }
  },

   userUnblock: async ({unblockUserId,programId}) => {
    try {
      const {data} = await httpService.post(
        `${customerEndpoint}/userUnblock/${unblockUserId}?programId=${programId}`,
      )
      return data
    } catch (error) {
      throw error
    }
  },
  getCheckoutData: async (programId,reservationId, newQty,userId) => {
    const requestData = {
      programId: programId,
      reservationId:reservationId,
      newQty: newQty,
      userId: userId
    };
    const {data} = await httpService.post(
      `${reservationEndpoint}/modifyreservationprepare`, requestData
    )
    return data
  },

  getSelectedRefundCheckoutData: async (programId,reservationId, newQty,userId,isDeposit, isBookingFee, isAdvanceReservationFee ) => {
    const requestData = {
      programId: programId,
      reservationId:reservationId,
      newQty: newQty,
      userId: userId,
      isDeposit, 
      isBookingFee, 
      isAdvanceReservationFee 
    };
    const {data} = await httpService.post(
      `${reservationEndpoint}/modifySelectedreservationprepare`, requestData
    )
    return data
  },
  
  modifyBooking: async (programId,reservationId,newQty,userId,previousQty,isDeposit,
    isBookingFee,
    isAdvanceReservationFee,
    creditCardId) => {
    const reservationData = {
      programId: programId,
      reservationId:reservationId,
      userId: userId,
      newQty: newQty,
      creditCardId: creditCardId,
      previousQty:previousQty,
      isDeposit,
      isBookingFee,
      isAdvanceReservationFee,
    };
    const {data} = await httpService.put(
      `${reservationEndpoint}/modifyreservation`, reservationData
    )
    return data
  },
  
  checkUserMembershipDetails: async (memberUserId,programId) => {
    const { data } = await httpService.get(`${memberSubscriptionEndpoint}/checkUserMembershipDetails?userId=${memberUserId}&programId=${programId}`)
    return data
  },

  getActiveMemberships: async (memberUserId,programId) => {
    const { data } = await httpService.get(`${memberSubscriptionEndpoint}/activeMembership?userId=${memberUserId}&programId=${programId}`)
    return data
  },

})

export default MemberService
