import { createActions } from '../../context'
import * as actions from './dashboard.actions'

export const createDashboard = store => ({
  availablePrograms: [],
  availableMembers: [],
  
  selectedBillingPlanid:[],
  selectedMembershipId:[],
  dashboardDetails: [],
  isRedirectToDashboard:false,
  cardExpiry:[],
  dashboardfilters:{
    reportFilterId:1,
    programId:'',
    billingPlanId:[],
    membershipId:''
  },
  dashboardFilterDate:{
    startDate:new Date(new Date().getFullYear(),new Date().getMonth(), 1),
    endDate:new Date()
  },
  availableMemberships:[],
  actions: createActions(actions)(store),
})
