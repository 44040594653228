import MemberService from './members.service'
import ReservationsService from '../reservations/reservations.service'

const reservationsService = ReservationsService()

const memberService = MemberService()

export const getMemberList = async (store, memberSearch) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  if (memberSearch) {
    try {
      store.setState(prevState => ({
        ...prevState,
        Member: {
          ...prevState.Member,
          members: [],
          totalItems: 0,
          currentTab: 'view',
          memberToEdit: undefined,
        },
      }))
      const { items, count } = await memberService.getMemberList(memberSearch)
      store.setState(prevState => ({
        ...prevState,
        Member: {
          ...prevState.Member,
          members: items,
          totalItems: count,
          currentTab: 'view',
          memberToEdit: undefined,
        },
      }))
      decrementLoading(store)
    } catch (e) {
      decrementLoading(store)
      throw e
    }
  }
}
export const getNonMemberCustomerInfoFromEmail = async (store, memberEmailInAffiliateModel) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  memberService
    .getMemberByEmail(memberEmailInAffiliateModel)
    .then(addMemberInfo => {
      store.setState(prevState => ({
        ...prevState,
        Member: {
          ...prevState.Member,
          addMemberInfo,
        },
      }))
      decrementLoading(store)
      return addMemberInfo
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}


export const validateUserForSameCourseAffiliate = async (store, memberEmailInAffiliate) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const data = reservationsService.validateMember(memberEmailInAffiliate)
    decrementLoading(store)
    return data
  }
  catch (error) {
    decrementLoading(store)
    if (error.response && error.response?.data === "Email is already in use.") {
      return error.response
    }
    else {
      throw error
    }
  }
}
export const createUser = async (store, { memberToCreate, programId }) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const addMemberInfo = await memberService.createUserInformation({ newUserInformation: memberToCreate, programId })
    store.setState(prevState => ({
      ...prevState,
      Member: {
        ...prevState.Member,
        addMemberInfo,
      },
    }))
    decrementLoading(store)

    showNotification({
      type: 'success',
      message: 'User created successfully!',
    })
    return addMemberInfo
  } catch (error) {
    decrementLoading(store)

    showNotification({
      type: 'error',
      message: 'User creation failed!',
    })
    throw error
  }
}

export const createMember = async (store, memberToCreate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    await memberService.create(memberToCreate)
    decrementLoading(store)
    await getMemberList(store)
    showNotification({
      type: 'success',
      message: 'Member created successfully!',
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Member creation failed!',
    })
    throw error
  }
}

export const updateMember = async (store, memberToUpdate) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    await memberService.update(memberToUpdate)
    decrementLoading(store)
    getMemberList(store).then(() => {
      showNotification({
        type: 'success',
        message: 'Member updated successfully',
      })
    })
  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'Member update failed!',
    })
    throw error
  }
}

export const selectMemberToEdit = (store, memberToEditId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  memberService
    .getMemberById(memberToEditId)
    .then(memberToEdit => {
      store.setState(prevState => ({
        ...prevState,
        Member: {
          ...prevState.Member,
          memberToEdit,
          currentTab: 'createOrEdit',
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const clearMemberToEdit = async store => {
  store.setState(prevState => ({
    ...prevState,
    Member: {
      ...prevState.Member,
      memberToEdit: undefined,
      currentTab: 'view',
      email: ''
    },
  }))
  await getMemberList(store)
}
export const clearAddMemberInfo = store => {
  store.setState(prevState => ({
    ...prevState,
    Member: {
      ...prevState.Member,
      currentTab: 'view',
      clickedAddMember: false,
      addMemberStepValue: undefined,
      addMemberInfo: undefined
    },
    MemberDetails: {
      ...prevState.MemberDetails,
      purchaseMembershipfilters: {
        filters: {
          programId: 0,
          billingPlanId: 0,
          membershipId: 0
        }
      },
      availablePrograms: [],
      availableMemberships: [],
      isMember: false,
      isPurchase: false
    }
  }))
}

export const resetAddMemberInfo = store => {
  store.setState(prevState => ({
    ...prevState,
    Member: {
      ...prevState.Member,
      addMemberInfo: undefined
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearMemberToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      Member: { ...prevState.Member, currentTab: tabName },
    }))
  }
}
export const setEmail = (store, email) => {
  store.setState(prevState => ({
    ...prevState,
    Member: { ...prevState.Member, email: email },
  }))
}

export const setAddMemberStepValue = (store, addMemberStepValue) => {
  store.setState(prevState => ({
    ...prevState,
    Member: { ...prevState.Member, addMemberStepValue: addMemberStepValue },
  }))
}

export const setClickedAddMember = (store, clickedAddMember) => {
  store.setState(prevState => ({
    ...prevState,
    Member: { ...prevState.Member, clickedAddMember: clickedAddMember },
  }))
}

export const setIsRedirectUrl = (store, isRedirectUrl) => {
  store.setState(prevState => ({
    ...prevState,
    Member: { ...prevState.Member, isRedirectUrl: isRedirectUrl },
  }))
}

export const resetMemberSearch = async store => {
  setClickedAddMember(store, false)
  setEmail(store, '')
  clearAddMemberInfo(store)
}

export const userUnblock = async (store, { unblockUserId, memberSearch, programId }) => {

  const { state } = store
  const { showNotification } = state.Notification.actions
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    return await memberService.userUnblock({unblockUserId, programId}).then((res) => {
      decrementLoading(store)
      if (memberSearch) {
        getMemberList(store, memberSearch)
      }
      setTimeout(() => {
        showNotification({
          type: 'success',
          message: 'Please contact Supreme Golf support to block this user',
        })
      }, 2000);
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: "The User will still be blocked",
    })
    throw error
  }
}

export const addUserUnblock = async (store, unblockUserId) => {
  const { state } = store
  const { showNotification } = state.Notification.actions
  const { incrementLoading, decrementLoading } = state.app.actions
  const programId = state.Member.addMemberStepValue.step1Value.programId;

  incrementLoading(store)
  try {
    return await memberService.userUnblock({unblockUserId,programId}).then((res) => {
      showNotification({
        type: 'success',
        message: 'Please contact Supreme Golf support to block this user',
      })
      decrementLoading(store)
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: "The User will still be blocked",
    })
    throw error
  }
}