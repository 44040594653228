import HttpService from '../../services/http.service'

const membershipSubscriptions = `${process.env.REACT_APP_API_ENDPOINT}/membershipSubscription`
const employeesEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/employee`
const httpService = HttpService()

const EmployeesService = () => ({
  getEmployees: async (programId, courseId) => {
    const {data} = await httpService.get(
      `${employeesEndpoint}/employees?programId=${programId}&courseId=${courseId}`,
    )

    return data
  },

  updateEmployee: async (customerId, subscriptionId, employeeId) => {
    await httpService.patch(
      `${membershipSubscriptions}/${customerId}/subscriptions/${subscriptionId}`,
      {referralEmployeeId: !employeeId ? null : employeeId},
    )
  },
  

  getActiveEmployeesAsync: async(programId, courseId) => {
        const {data} = await httpService.get(    
          `${employeesEndpoint}/ActiveEmployees?programId=${programId}&courseId=${courseId}`,
        )
        return data
      },
})

export default EmployeesService
