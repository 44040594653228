import {connect} from '../../context'

import Notification from './Notification'

const mapStateToProps = state => ({
  type: state.Notification.type,
  message: state.Notification.message,
  isShow: state.Notification.isShow,
  hideNotification: state.Notification.actions.hideNotification,
})

export default connect(mapStateToProps, Notification)
