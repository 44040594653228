import axios from 'axios'

import { USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY,SITE_UNDER_MAINTENANCE } from '../utils/userTokenKey'

import AuthService from './auth.service'

const wasCancelled = error => !!(error && error.__CANCEL__)

const HttpService = () => {
  const httpService = axios.create()

  httpService.interceptors.request.use(
    config => {
      const accessToken = localStorage.getItem(USER_TOKEN_KEY)
      if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`
        // eslint-disable-next-line eqeqeq
        if (config._retry == undefined) {
          config._retry = false
        }
      }

      return config
    },
    error => Promise.reject(error),
  )

  httpService.interceptors.response.use(
    function (response) {
      // If the request succeeds, we don't have to do anything and just return the response
      return response
    },
    async function (error) {
      const originalRequest = error.config

      if (wasCancelled(error)) {
        return Promise.reject('REQUEST_CANCELLED')
      }
      // if the method that failed was the refresh token itself => redirect to login
      else if (
        error.response &&
        (error.response.status === 401 || error.response.status === 400) &&
        originalRequest.url.includes('tokenFromRefreshToken')
      ) {
        localStorage.setItem(USER_TOKEN_KEY, null)
        localStorage.setItem(USER_REFRESH_TOKEN_KEY, null)
        window.location.replace('/login')
        return Promise.reject(error)
      } 
      else if (error.response && (error.response.status === 503)) {
        localStorage.setItem(SITE_UNDER_MAINTENANCE, "True")
        window.location.replace('/undermaintenance')
      }else if (error.response.status !== 401) {
        return Promise.reject(error)
      }

      // if this is the first failed login try to refresh if it isn't goto login
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        const authService = AuthService()
        originalRequest._retry = true
        const accessToken = await authService.refreshToken()
        // change authorization header
        httpService.defaults.headers.common['Authorization'] =
          'Bearer ' + accessToken
        // return originalRequest object with Axios.
        return httpService(originalRequest)
      }
      else if (error.response && (error.response.status === 503)) {
        localStorage.setItem(USER_REFRESH_TOKEN_KEY, "False")
        window.location.replace('/undermaintenance')
      } else {
        localStorage.setItem(USER_TOKEN_KEY, null)
        localStorage.setItem(USER_REFRESH_TOKEN_KEY, null)
        localStorage.setItem(USER_REFRESH_TOKEN_KEY, "True")
        window.location.replace('/login')
        return Promise.reject(error)
      }
    },
  )

  httpService.generateCancellationToken = () => {
    return axios.CancelToken.source()
  }

  return httpService
}

export default HttpService
