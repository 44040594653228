import * as queryString from 'query-string'
import * as flatten from 'flat'
import { saveAs } from 'file-saver';

import HttpService from '../../services/http.service'

const reportsServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/reporting`
const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`
const billingPlanServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipBillingPlan`
const paymentProcessorServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/PaymentProcessors`
const reservationServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/Reservation/courseDetails`
const memberSubscriptionEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipSubscription`
const httpService = HttpService()

const ReportsService = () => ({
  getCoursesByProgram: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${courseServiceEndpoint}?programId=${programId}`,
    )
    
    return data
    }
  },

  getCoursesByProgramForReservationReport: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${reservationServiceEndpoint}?programId=${programId}`,
    )
    
    return data
    }
  },

  getSupremeGolfUserIdbySubscriptionId: async subscriptionId => {
    if(subscriptionId)
    {
    const {data} = await httpService.get(
      `${memberSubscriptionEndpoint}/supremeGolfUserId/${subscriptionId}`,
    )
    return data
    }
  },

  

  getBillingPlansByProgram: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${billingPlanServiceEndpoint}?programId=${programId}`,
    )

    return data
    }
  },
   
  generateMemberReport: async filters => {
    console.log(filters)
    const {data} = await httpService.post(
      `${reportsServiceEndpoint}/members`,filters,
    )
    return data
  },

 

  generateDashboardDetailCount: async filters => {
    console.log(filters)
    const {data} = await httpService.post(
      `${reportsServiceEndpoint}/dashboardDetailCount`,filters
    )

    return data
  },
   
  exportMemberReport: async filters => {
    exportReport("Members.xlsx", "members/exportToExcel", filters)
  },
  
  generatePaymentReport: async filters => {
    console.log(filters)
    const {data} = await httpService.post(
      `${reportsServiceEndpoint}/payments`, filters
    )

    return data
  },
   
  generateFutureActiveMemberDetailsReport: async filters => {
    console.log(filters)
    const {data} = await httpService.post(
      `${reportsServiceEndpoint}/passDetails`,filters
    )
   
    return data
  },
 
  getProgramPaymentProcessors:  async programId => {
    const { data } = await httpService.get(
        `${paymentProcessorServiceEndpoint}/GetProgramPaymentProcessors?programId=${programId}`,
    )
    return data
  },
 
  exportPaymentReport: async filters => {
    exportReport("Payments.xlsx", "payments/exportToExcel", filters)
  },
   
  exportExpectionReport: async filters => {
    exportReport(filters.fileName, "exceptionReport/exportToExcel", filters)
  },

  exportFutureActiveMemberDetailsReport: async filters => {
    exportReport("ActivationStatusReport.xlsx", "passDetails/exportToExcel", filters)
  },
  
  generateCancellationToken: () => httpService.generateCancellationToken(),

  generateReservationReport: async filters => {
    console.log(filters)  
    const {data} = await httpService.post(
      `${reportsServiceEndpoint}/reservation`,filters
    )

    return data
  },  
    
  exportReservationReport: async filters => {
    exportReport("ReservationDetails.xlsx", "reservation/exportToExcel", filters)
  },
   
mailMemberReport: async filters => { 
  const searchQuery = queryString.stringify(flatten(filters, {safe: true}), {
    arrayFormat: 'index',
  })
  const {data} = await httpService.get(
    `${reportsServiceEndpoint}/members/mailMembersReport?${searchQuery}`,
  )
  return data
},

getExelReportHistory: async ()=>{

  const {data}= await httpService.get(
    `${reportsServiceEndpoint}/Report/ExelReportHistory`,
  )
  return data

},

mailPaymentReport: async filters => {    
  const searchQuery = queryString.stringify(flatten(filters, {safe: true}), {
    arrayFormat: 'index',
  })
  const {data} = await httpService.get(
    `${reportsServiceEndpoint}/payments/mailPaymentsReport?${searchQuery}`,
  )
  return data
},

mailReservationReport: async filters => {    
  const searchQuery = queryString.stringify(flatten(filters, {safe: true}), {
    arrayFormat: 'index',
  })
  const {data} = await httpService.get(
    `${reportsServiceEndpoint}/reservation/mailReservationReport?${searchQuery}`,
  )
  return data
},

mailFutureActiveMemberDetailsReport: async filters => {    
  const searchQuery = queryString.stringify(flatten(filters, {safe: true}), {
    arrayFormat: 'index',
  })
  const {data} = await httpService.get(
    `${reportsServiceEndpoint}/passDetails/mailPassDetailsReport?${searchQuery}`,
  )
  return data
},

getExistingCardType: async ()=>{

  const {data}= await httpService.get(
    `${reportsServiceEndpoint}/Report/ExistingCardType`,
  )
  return data

},
})
      
const exportReport = async (fileName, path, filters) => {
  const searchQuery = queryString.stringify(flatten(filters, {safe: true}), {
    arrayFormat: 'index',
  })
  const link = `${reportsServiceEndpoint}/${path}?${searchQuery}`
  const response = await httpService.get(link, {responseType: 'blob'});
  const {data, headers} = response;
  const fileNameHeader = "x-suggested-filename";
  const suggestedFileName = headers[fileNameHeader];
  const effectiveFileName = (suggestedFileName === undefined
              ? fileName
              : suggestedFileName);
  saveAs(data, effectiveFileName);
}


export default ReportsService
