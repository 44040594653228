import {createActions} from '../../context'
import * as actions from './membership.actions'

export const createMembership = store => {
  return {
    memberships: [],
    membershipToEdit: undefined,
    membershipToEditReciprocity: undefined,
    membershipReciprocityCourses: undefined,
    membershipToView: undefined,
    possibleMembershipUpgradeSources: [],
    currentTab: 'view',
    possibleReciprocalCourses: [],
    planEffectiveDates:[],
    isPOSIntegrated:false,
    actions: createActions(actions)(store),
  }
}
