import ProgramService from '../program/program.service'
import ReportsService from './reports.service'

const reportsService = ReportsService()
const programService = ProgramService()

export const getAvailablePrograms = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  try {
    const list = await programService.getProgramsForUser()
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        availablePrograms: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableCourses = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getCoursesByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        availableCourses: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableCoursesForReport = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getCoursesByProgramForReservationReport(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        availableCoursesForReport: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableBillingPlans = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getBillingPlansByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        availableBillingPlans: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const generateFilters = (store,filters) => {
  //validate if billing plan selected details based on Membership
  if(filters.programId && filters.programId!="" && filters.programId >0 && filters.membershipId && filters.membershipId.length>0 && store.state.Dashboard.availableMemberships){
     
    let selectedMembershipBillingPlanId =[]
     filters.membershipId.forEach(function (membershipValue) {
     if (store.state.Dashboard.availableMemberships && membershipValue>0) {
       var data=store.state.Dashboard.availableMemberships.find(m => m.id == membershipValue);
       if(data && data.billingPlans)
       selectedMembershipBillingPlanId=selectedMembershipBillingPlanId.length>0?[...selectedMembershipBillingPlanId,...data.billingPlans]:data.billingPlans
     }
   });

   // User not selected the billing plan , so default set as all the billing plan id's
   if (selectedMembershipBillingPlanId && filters.billingPlanId && filters.billingPlanId.length == 0) {
       filters.billingPlanId=selectedMembershipBillingPlanId.map(a=>a.id)
     }
 }
 return filters;
}

export const generateMemberDetailsReport = async (
  store,
  {filters, freshSearch = false},
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentMemberFilters
  filters =generateFilters(store,filters);
  incrementLoading(store)

  try {
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        memberDetailsReport: [],
        memberDetailsTotalItems: 0,
      },
    }))

    if (freshSearch) {
      baseFilters = {
        pageNumber: 1,
        pageSize: 25,
        column: 'memberSince',
        direction: 'asc',
      }
    }

    const fullFilter = {...baseFilters, ...filters}

    const {items, count} = await reportsService.generateMemberReport(fullFilter)
 
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        memberDetailsReport: items,
        memberDetailsTotalItems: count,
        currentMemberFilters: fullFilter,
        memberReportIsGenerated: true,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const exportMemberDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentMemberFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)
  try {
    reportsService.exportMemberReport(baseFilters)
  } finally {
    decrementLoading(store)
  }
}

export const generatePaymentDetailsReport = async (
  store,
  {filters, freshSearch = false},
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentPaymentFilters
  filters =generateFilters(store,filters);
  incrementLoading(store)

  try {
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        paymentDetailsReport: [],
        paymentDetailsTotalItems: 0,
      },
    }))

    if (freshSearch) {
      baseFilters = {
        pageNumber: 1,
        pageSize: 25,
        column: 'paymentDate',
        direction: 'desc',
      }
    }

    const fullFilter = {...baseFilters, ...filters}

    const {items, count} = await reportsService.generatePaymentReport(
      fullFilter,
    )
  
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        paymentDetailsReport: items,
        paymentDetailsTotalItems: count,
        currentPaymentFilters: fullFilter,
        paymentReportIsGenerated: true,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const exportPaymentDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentPaymentFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    reportsService.exportPaymentReport(baseFilters)
  } finally {
    decrementLoading(store)
  }
}

export const exportExpectionDetailsReport = async (store, reportDate) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  try {
    reportsService.exportExpectionReport(reportDate)
  } finally {
    decrementLoading(store)
  }
}

export const getSupremeGolfUserIdbySubscriptionId = async (store, subscriptionId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  try {
    const supremeGolfUserId = await reportsService.getSupremeGolfUserIdbySubscriptionId(subscriptionId)
   return supremeGolfUserId;
  } finally {
    decrementLoading(store)
  }
}


export const setCurrentTab = (store, tabName) => {
  const isDifferentTab = tabName !== store.state.Reports.currentTab
  const cleanResults = {
    paymentDetailsReport: [],
    paymentDetailsTotalItems: 0,
    memberDetailsReport: [],
    memberDetailsTotalItems: 0,
    memberReportIsGenerated: false,
    paymentReportIsGenerated: false,
    reservationReportIsGenerated:false,
    futureActiveMemberReportIsGenerated:false,
  }

  if (isDifferentTab) {
    store.setState(prevState => ({
      ...prevState,
      Reports: {...prevState.Reports, currentTab: tabName, ...cleanResults},
    }))
  } else {
    store.setState(prevState => ({
      ...prevState,
      Reports: {...prevState.Reports, currentTab: tabName},
    }))
  }
}
export const cleanGridResults   = (store) => {
  const cleanResults = {
    paymentDetailsReport: [],
    paymentDetailsTotalItems: 0,
    memberDetailsReport: [],
    memberDetailsTotalItems: 0,
    memberReportIsGenerated: false,
    paymentReportIsGenerated: false,
    reservationReportIsGenerated:false,
    futureActiveMemberReportIsGenerated:false,
  }
  store.setState(prevState => ({
    ...prevState,
    Reports: {...prevState.Reports,...prevState.currentTab, ...cleanResults},
  }))
}

export const generateCancellationToken = () =>
  reportsService.generateCancellationToken()

export const getProgramPaymentProcessors = async (store, programId) => {
    const state = store.state
    const {incrementLoading, decrementLoading} = state.app.actions
    incrementLoading(store)
    try {
      const list = await reportsService.getProgramPaymentProcessors(programId ?? '')
      store.setState(prevState => ({
        ...prevState,
        Reports: {
          ...prevState.Reports,
          availableProgramPaymentProcessors: list,
        },
      }))
    } finally {
      decrementLoading(store)
    }
}

export const generateReservationDetailsReport = async (
  store,
  {filters, freshSearch = false},
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentReservationFilters

  incrementLoading(store)

  try {
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
       reservationDetailsReport: [],
       reservationDetailsTotalItems: 0,
      },
    }))

    if (freshSearch) {
      baseFilters = {
        pageNumber: 1,
        pageSize: 25,
        column: 'dateOfBooking',
        direction: 'desc',
      }
    }

    const fullFilter = {...baseFilters, ...filters}

    const {items, count} = await reportsService.generateReservationReport(fullFilter)
 
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        reservationDetailsReport: items,
        reservationDetailsTotalItems: count,
        currentReservationFilters: fullFilter,
        reservationReportIsGenerated: true,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const exportReservationDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentReservationFilters

  incrementLoading(store)

  try {
    reportsService.exportReservationReport(baseFilters)
  } finally {
    decrementLoading(store)
  }
}

export const generateFutureActiveMemberDetailsReport = async (
  store,
  {filters, freshSearch = false},
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentFutureActiveMemberFilters
  filters =generateFilters(store,filters);
  incrementLoading(store)

  try {
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        futureActiveMemberDetailsReport: [],
        futureActiveMemberDetailsTotalItems: 0,
        futureActiveMemberDetailsTotalSummaryItems:0,
        futureActiveMemberDetailsTotalPassItems:0,
        futureActiveMemberDetailsTotalFailItems:0,
      },
    }))

    if (freshSearch) {
      baseFilters = {
        pageNumber: 1,
        pageSize: 25,
        column: 'passEffectiveDate',
        direction: 'asc',
      }
    }

    const fullFilter = {...baseFilters, ...filters}

    const {items, count} = await reportsService.generateFutureActiveMemberDetailsReport(fullFilter)
    store.setState(prevState => ({
      ...prevState,
      Reports: {
        ...prevState.Reports,
        futureActiveMemberDetailsReport: items,
        futureActiveMemberDetailsTotalItems: count,
        currentFutureActiveMemberFilters: fullFilter,
        futureActiveMemberReportIsGenerated: true,
        futureActiveMemberDetailsTotalSummaryItems:items.length>0?items[0].totalSummaryCount:0,
        futureActiveMemberDetailsTotalPassItems:items.length>0?items[0].passCount:0,
        futureActiveMemberDetailsTotalFailItems:items.length>0?items[0].failCount:0
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const exportFutureActiveMemberDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentFutureActiveMemberFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    reportsService.exportFutureActiveMemberDetailsReport(baseFilters)
  } finally {
    decrementLoading(store)
  }
}

export const mailMemberDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentMemberFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    const status = reportsService.mailMemberReport(baseFilters)    
  } finally {
    decrementLoading(store)
  }
}

export const mailPaymentDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentPaymentFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    const status = reportsService.mailPaymentReport(baseFilters)    
  } finally {
    decrementLoading(store)
  }
}

export const mailReservationDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentReservationFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    const status = reportsService.mailReservationReport(baseFilters)    
  } finally {
    decrementLoading(store)
  }
}

export const mailFutureActiveMemberDetailsReport = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  let baseFilters = state.Reports.currentFutureActiveMemberFilters
  baseFilters =generateFilters(store,baseFilters);
  incrementLoading(store)

  try {
    const status = reportsService.mailFutureActiveMemberDetailsReport(baseFilters)    
  } finally {
    decrementLoading(store)
  }
}


export const getExelReportHistory =async store =>{
  const state = store.state
  const list = await reportsService.getExelReportHistory();
  store.setState(prevState => ({
    ...prevState,
    Reports: {
      ...prevState.Reports,
      statusList: list,
    },
  }))
// return statusList;
}

export const getExistingCardType =async store =>{
  const state = store.state
  const CardTypelist = await reportsService.getExistingCardType();
  store.setState(prevState => ({
    ...prevState,
    Reports: {
      ...prevState.Reports,
      availableCardType: CardTypelist,
    },
  }))
// return statusList;
}