import MigrationService from './migration.service'

const migrationService = MigrationService()

export const getMigrationList = async (store, showLoading) => {
  const state = store.state
  const {currentGlobalProgramId} = state.Program
  const {incrementLoading, decrementLoading} = state.app.actions

  if (showLoading) incrementLoading(store)
  try {
    const list = await migrationService.getMigrationList(currentGlobalProgramId)
    store.setState(prevState => ({
      ...prevState,
      Migration: {
        ...prevState.Migration,
        migrations: list,
        currentTab: 'view',
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
    if (showLoading) decrementLoading(store)
  } catch (e) {
    if (showLoading) decrementLoading(store)
    throw e
  }
}

export const createMigration = async (store, migrationToCreate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  try {
    await migrationService.postMigration(migrationToCreate)
    decrementLoading(store)
    getMigrationList(store, true).then(() => {
      showNotification({
        type: 'success',
        message: 'Migration created successfully!',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Migration creation failed!',
    })
    throw error
  }
}

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    Migration: {...prevState.Migration, currentTab: tabName},
  }))
}
export const downloadFile = (store, link) => {
  migrationService.downloadFile(link)
}
