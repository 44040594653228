import ManageUsersService from './manageUsers.service'

const userService = ManageUsersService()

export const getUserList = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await userService.getUserList(programId)
    decrementLoading(store)

    store.setState(prevState => ({
      ...prevState,
      ManageUsers: {
        ...prevState.ManageUsers,
        users: list,
        currentTab: 'view',
        userToEdit: undefined,
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))

    return list
  } catch (error) {
    decrementLoading(store)
    throw error
  }
}

export const createUser = async (store, userToCreate) => {
  const {state} = store
  const {showNotification} = state.Notification.actions
  const {incrementLoading, decrementLoading} = state.app.actions
  const programId = state.Program.currentGlobalProgramId

  incrementLoading(store)
  try {
    await userService.create(userToCreate)
    decrementLoading(store)
    return await getUserList(store, programId).then(() => {
      showNotification({
        type: 'success',
        message: 'User created successfully!',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'User creation failed!',
    })
    throw error
  }
}

export const updateUser = async (store, userToUpdate) => {
  const {state} = store
  const {showNotification} = state.Notification.actions
  const {incrementLoading, decrementLoading} = state.app.actions
  const programId = state.Program.currentGlobalProgramId

  incrementLoading(store)
  try {
    await userService.update(userToUpdate)
    setCurrentTab(store, 'view')

    decrementLoading(store)
    return await getUserList(store, programId).then(() => {
      showNotification({
        type: 'success',
        message: 'User updated successfully',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'User update failed!',
    })
    throw error
  }
}

export const deleteUser = async (store, userToDeleteId) => {
  const {state} = store
  const {showNotification} = state.Notification.actions
  const {incrementLoading, decrementLoading} = state.app.actions
  const programId = state.Program.currentGlobalProgramId

  incrementLoading(store)
  try {
    await userService.delete(userToDeleteId)
    setCurrentTab(store, 'view')
    decrementLoading(store)
    return await getUserList(store, programId).then(() => {
      showNotification({
        type: 'success',
        message: 'User deleted successfully',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'User delete failed!',
    })
    throw error
  }
}

export const resendUserInvitation = async (store, userToSendInvitationId) => {
  const {state} = store
  const {showNotification} = state.Notification.actions
  const {incrementLoading, decrementLoading} = state.app.actions
  const programId = state.Program.currentGlobalProgramId

  incrementLoading(store)
  try {
    await userService.resendUserInvitation(userToSendInvitationId)
    setCurrentTab(store, 'view')
    decrementLoading(store)
    return await getUserList(store, programId).then(() => {
      showNotification({
        type: 'success',
        message: 'User invitation was sent',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: "User invitation wasn't sent!",
    })
    throw error
  }
}

export const selectUserToEdit = (store, userToEditId) => {
  const {state} = store
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  userService
    .getUserById(userToEditId)
    .then(userToEdit => {
      store.setState(prevState => ({
        ...prevState,
        ManageUsers: {
          ...prevState.ManageUsers,
          userToEdit,
          currentTab: 'createOrEdit',
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)

      throw error
    })
}

export const clearUserToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    ManageUsers: {
      ...prevState.ManageUsers,
      userToEdit: undefined,
      currentTab: 'view',
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearUserToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      ManageUsers: {...prevState.ManageUsers, currentTab: tabName},
    }))
  }
}

export const getSubRolesForCreate = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await userService.getSubRolesCreate()
    decrementLoading(store)

    store.setState(prevState => ({
      ...prevState,
      ManageUsers: {
        ...prevState.ManageUsers,
        subRolesForCreateOrEdit: list,
      },
    }))
  } catch (error) {
    decrementLoading(store)
    throw error
  }
}

export const getSubRolesForEdit = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await userService.getSubRolesEdit()
    decrementLoading(store)

    store.setState(prevState => ({
      ...prevState,
      ManageUsers: {
        ...prevState.ManageUsers,
        subRolesForCreateOrEdit: list,
      },
    }))
  } catch (error) {
    decrementLoading(store)
    throw error
  }
}
