import HttpService from '../../services/http.service'

const httpService = HttpService()
const billingPlansEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipBillingPlan`
const membershipsEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership`
const migrationsEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/subscriptionMigration`

const UpgradesService = () => ({
  getMembershipsForUser: async () => {
    const {data} = await httpService.get(
      `${membershipsEndpoint}/listmembershipandbillingplans`,
    )

    return data
  },

  getActiveMembersCount: async billingPlanId => {
    const {data} = await httpService.get(
      `${billingPlansEndpoint}/memberCount/${billingPlanId}`,
    )
    return data.count
  },

  upgradeMembers: async (
    sourceMembershipId,
    destinationMembershipId,
    sourceMembershipBillPlanId,
    destinationMembershipBillPlanId,
    scheduleId
  ) => {
    
    await httpService.post(`${migrationsEndpoint}`, {
      sourceMembershipId,
      destinationMembershipId,
      sourceMembershipBillPlanId,
      destinationMembershipBillPlanId,
      scheduleId
    })
  },

  getPastUpgrades: async (pageNumber, pageSize) => {
    const {data} = await httpService.get(
      `${migrationsEndpoint}/list?page.pageNumber=${pageNumber}&page.pageSize=${pageSize}`,
    )
    return {
      items: data.migrationItems.map(i => ({
        ...i,
        createdDate: new Date(i.createdDate),
        link: `${migrationsEndpoint}/file/${i.id}`,
      })),
      count: data.totalRows,
    }
  },

  getSinglePastUpgrade: async link => {
    const response = await httpService.get(link, {responseType: 'blob'})
    const {data, headers} = response
    const fileNameHeader = 'x-suggested-filename'
    const suggestedFileName = headers[fileNameHeader]
    const fileName =
      suggestedFileName === undefined ? 'result.csv' : suggestedFileName

    return {data, fileName}
  },

  getTargetMembershipAndBillingPlan: async sourceMembershipId => {
    const {data} = await httpService.get(
      `${membershipsEndpoint}/targetMembershipAndBillingPlans?sourceMembershipId=${sourceMembershipId}`
    )

    return data
  }
})

export default UpgradesService
