import {createActions} from '../../context'
import * as actions from './members.actions'

export const createMember = store => {
  return {
    members: [],
    totalItems: 0,
    memberToEdit: undefined,
    addMemberInfo: undefined,
    currentTab: 'view',
    thereWasASearch: false,
    addMemberStepData:{step1Value: undefined, step2Value: undefined, step3Value: undefined},
    actions: createActions(actions)(store),
    email: '',
    isNationalNetworkMember:false
  }
}
