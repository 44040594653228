import ProgramService from '../program/program.service'
import ReportsService from '../reports/reports.service'
import EmployeesService from './employees.service'

const employeesService = EmployeesService()
const reportsService = ReportsService()
const programService = ProgramService()

export const resetEmployeesListStore = store => {
  store.setState(prevState => ({
    ...prevState,
    Employees: {
      ...prevState.Employees,
      employees: [],
      totalItems: 0,
      availablePrograms: [],
      availableCourses: [],
    },
  }))
}

export const resetStore = store => {
  resetEmployeesListStore(store)
  store.setState(prevState => ({
    ...prevState,
    Employees: {
      ...prevState.Employees,
      employeToEdit: undefined,
      currentTab: 'view',
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    Employees: {...prevState.Employees, currentTab: tabName},
  }))

  if (tabName === 'view') {
    clearEmployeeToEdit(store)
  }
}

export const getEmployees = async (store, employeeFilters) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const {items, count} = await employeesService.getEmployees(employeeFilters)
    store.setState(prevState => ({
      ...prevState,
      Employees: {
        ...prevState.Employees,
        employees: items,
        totalItems: count,
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailablePrograms = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await programService.getProgramsForUser()
    store.setState(prevState => ({
      ...prevState,
      Employees: {
        ...prevState.Employees,
        availablePrograms: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableCourses = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getCoursesByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Employees: {
        ...prevState.Employees,
        availableCourses: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const selectEmployeeToEdit = async (store, employeeToEditId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)

  try {
    const employeeToEdit = await employeesService.getEmployeeById(
      employeeToEditId,
    )
    store.setState(prevState => ({
      ...prevState,
      Employees: {
        ...prevState.Employees,
        employeeToEdit,
        currentTab: 'createOrEdit',
      },
    }))
    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
    throw error
  }
}

export const clearEmployeeToEdit = async store => {
  store.setState(prevState => ({
    ...prevState,
    Employees: {
      ...prevState.Employees,
      employeeToEdit: null,
      currentTab: 'view',
    },
  }))
}

export const createEmployee = async (store, employeeToCreate) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  const showNotification = state.Notification.actions.showNotification

  incrementLoading(store)

  try {
    await employeesService.create(employeeToCreate)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Employee created successfully!',
    })
    setCurrentTab(store, 'view')
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Employee creation failed!',
    })
    throw error
  }
}

export const updateEmployee = async (store, employeeToUpdate) => {
  const showNotification = store.state.Notification.actions.showNotification
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    await employeesService.update(employeeToUpdate)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Employee updated successfully',
    })
    clearEmployeeToEdit(store)
  } catch (error) {
    let message = null
    if (error.response) {
      if (error.response.data.errors) {
        let messageArray = []
        for (const property in error.response.data.errors) {
          messageArray = messageArray.concat(
            error.response.data.errors[property],
          )
        }
        message = messageArray.join(',')
      }
    }
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: message || 'Employee update failed!',
    })
    throw error
  }
}
