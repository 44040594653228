import { createActions } from '../../context'
import * as actions from './reports.actions'

export const createReports = store => ({
  currentTab: 'memberDetails',
  availablePrograms: [],
  statusList:[],
  availableCourses: [],
  availableCoursesForReport: [],
  availableBillingPlans: [],
  selectedProgramId: undefined,
  memberFilterLocalStorageKey: 'memberReportFilters',
  memberDetailsReport: [],
  memberDetailsTotalItems: 0,
  memberReportIsGenerated: false,
  currentMemberFilters: {
    pageNumber: 1,
    pageSize: 5,
    column: 'memberSince',
    direction: 'asc',
  },
  paymentDetailsReport: [],
  paymentDetailsTotalItems: 0,
  paymentReportIsGenerated: false,
  paymentFilterLocalStorageKey: 'paymentReportFilters',
  currentPaymentFilters: {
    pageNumber: 1,
    pageSize: 5,
    column: 'paymentDate',
    direction: 'desc',
  },
  reservationDetailsReport: [],
  reservationDetailsTotalItems: 0,
  reservationReportIsGenerated: false,
  reservationLocalStorageKey: 'reservationReportFilters',
  currentReservationFilters: {
    pageNumber: 1,
    pageSize: 5,
    column: 'dateOfBooking',
    direction: 'desc',
  },
  futureActiveMemberDetailsReport: [],
  futureActiveMemberDetailsTotalItems: 0,
  futureActiveMemberDetailsTotalSummaryItems:0,
  futureActiveMemberDetailsTotalPassItems: 0,
  futureActiveMemberDetailsTotalFailItems: 0,
  futureActiveMemberReportIsGenerated: false,
  currentFutureActiveMemberFilters: {
    pageNumber: 1,
    pageSize: 5,
    column: 'passEffectiveDate',
    direction: 'asc',
  },
  availableProgramPaymentProcessors: [],
  reportStatusList: ['Member'],
  actions: createActions(actions)(store),
})
