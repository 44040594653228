import {createActions} from '../../context'
import * as actions from './alert-content.actions'

export const createAlertContent = store => ({
  contents: [],
  contentForSelect: [],
  availablePrograms: [],
  alertContentToEdit: undefined,
  selectedViewProgramId:undefined,
  selectedViewCourseTeeSheetId:undefined,
  currentTab: 'view',
  currentCourses: [],
  possibleProgramCourses: [],
  isSingleProgram:false,
  singleProgramId:0,
  teeSheetForAlertContent:[],
  actions: createActions(actions)(store),
})
