import React from 'react'
import ReactDOM from 'react-dom'

import {Provider} from './context'
import {createStore} from './store'
import ErrorBoundary from './components/error-boundary/ErrorBoundary'
import {unregister} from './serviceWorker'

import './index.scss'
import 'normalize.css'
import App from './App'


ReactDOM.render(
  // we need a custom spinner for loading transitions
  <ErrorBoundary>
    <Provider store={createStore}>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()

