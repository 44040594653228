import React from 'react'

import {ReactComponent as Spinner} from '../../assets/img/spinner.svg'
import './spinner.scss'

const AppSpinner = () => (
  <div className="backdrop">
    <Spinner className="app-spinner"></Spinner>
  </div>
)

export default AppSpinner
