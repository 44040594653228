import HttpService from '../../services/http.service'

const userServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/user`
const programServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/program`
const httpService = HttpService()

const ManageUsersService = () => ({
  getUserList: async programId => {
    const {data} = await httpService.get(
      `${userServiceEndpoint}/list?Filter.ProgramId=${programId}`,
    )
    return data
  },

  getUserById: async userId => {
    const {data} = await httpService.get(
      `${userServiceEndpoint}/userwithrole/${userId}`,
    )

    return data
  },

  create: async userToCreate => {
    try {
      const {data} = await httpService.post(
        `${userServiceEndpoint}/createUserWithRole`,
        userToCreate,
      )

      return data
    } catch (error) {
      throw error
    }
  },

  update: async userToUpdate => {
    try {
      const {data} = await httpService.put(
        `${userServiceEndpoint}/updateUserWithRole/${userToUpdate.id}`,
        userToUpdate,
      )

      return data
    } catch (error) {
      throw error
    }
  },

  delete: async userToDeleteId => {
    try {
      const {data} = await httpService.delete(
        `${userServiceEndpoint}/${userToDeleteId}`,
      )

      return data
    } catch (error) {
      throw error
    }
  },

  resendUserInvitation: async userToDeleteId => {
    try {
      const {data} = await httpService.post(
        `${userServiceEndpoint}/resendInvitation/${userToDeleteId}`,
      )

      return data
    } catch (error) {
      throw error
    }
  },

  getSubRolesCreate: async () => {
    try {
      const {data} = await httpService.get(
        `${userServiceEndpoint}/getSubRolesCreate`,
      )
      return data
    } catch (error) {
      throw error
    }
  },

  getSubRolesEdit: async () => {
    try {
      const {data} = await httpService.get(
        `${userServiceEndpoint}/getSubRolesEdit`,
      )
      return data
    } catch (error) {
      throw error
    }
  },

  isUserEmailUnique: async email => {
    try {
      const {data} = await httpService.get(
        `${userServiceEndpoint}/isEmailUsed/${email}`,
      )

      return !data
    } catch (error) {
      throw error
    }
  },

  getCourses: async (programId, cancellationToken) => {
    const {
      data,
    } = await httpService.get(
      `${programServiceEndpoint}/${programId}/courses`,
      {cancelToken: cancellationToken},
    )
    return data
  },
})

export default ManageUsersService
