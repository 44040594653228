import ContentService from './alert-content.service'
import ProgramService from '../program/program.service'

const alertContentService = ContentService()
const programService = ProgramService()

export const getAlertContentList = async (store, { programId, courseTeeSheetId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  return alertContentService
    .getAlertContentList(programId, courseTeeSheetId)
    .then(list => {
      // Handle the Drag and Drop for only one Item in the list 
      // set disabled true for remove Draggle  event in the Table
      if (list && list.length == 1) {
        list[0].disabled = true;
      }
      store.setState(prevState => ({
        ...prevState,
        AlertContent: {
          ...prevState.AlertContent,
          alertContentViewList: list,
          currentTab: 'view',
          alertContentToEdit: null,
          teeSheetForAlertContent: [],
          selectedViewCourseTeeSheetId: courseTeeSheetId,
          selectedViewProgramId: programId
        },
      }))

      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const createAlert = async (store, contentToCreate) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  const showNotification = state.Notification.actions.showNotification

  const contentToCreateModified = {
    ...contentToCreate,
  }
  incrementLoading(store)

  try {
    await alertContentService.create(contentToCreateModified)
    showNotification({
      type: 'success',
      message: 'Alert created successfully!',
    })
   
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Alert creation failed!',
    })
    throw error
  }
  finally{
    store.setState(prevState => ({
      ...prevState,
      AlertContent: {
        ...prevState.AlertContent,
        currentTab: 'view',
        alertContentToEdit: null,
      },
    }))
    decrementLoading(store)
    refreshList(store)
  }
}

export const updateContent = async (store, contentToUpdate) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = state.Notification.actions.showNotification

  incrementLoading(store)

  try {
    await alertContentService.update(contentToUpdate)
    showNotification({
      type: 'success',
      message: 'Alert updated successfully',
    })
    store.setState(prevState => ({
      ...prevState,
      AlertContent: {
        ...prevState.AlertContent,
        currentTab: 'view',
        alertContentToEdit: null,
      },
    }))
    decrementLoading(store)
    refreshList(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Alert update failed!',
    })
    throw error
  }
}

export const updateAlertContentRank = async (store, contentToUpdate) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = state.Notification.actions.showNotification

  incrementLoading(store)

  try {
    await alertContentService.updateRank(contentToUpdate)
    showNotification({
      type: 'success',
      message: 'Alert Rank updated successfully',
    })
    decrementLoading(store)
    refreshList(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Alert Rank update failed!',
    })
    throw error
  }
}

export const deleteAlertContent = async (store, { alertContentId, programId, courseTeeSheetId }) => {
  const { state } = store
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = state.Notification.actions.showNotification
  incrementLoading(store)
  try {
    await alertContentService.delete(alertContentId)
    setCurrentTab(store, 'view')
    decrementLoading(store)
    return await getAlertContentList(store, { programId, courseTeeSheetId }).then(() => {
      showNotification({
        type: 'success',
        message: 'Alert deleted successfully',
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Alert delete failed!',
    })
    throw error
  }
}

export const selectAlertContentToEdit = async (store, contentToEditId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const alertContentToEdit = await alertContentService.getContentById(contentToEditId)
    const teeSheetForAlertContent = await alertContentService.getPossibleContentCourses(alertContentToEdit.programId)
    store.setState(prevState => ({
      ...prevState,
      AlertContent: {
        ...prevState.AlertContent,
        alertContentToEdit,
        teeSheetForAlertContent,
        currentTab: 'createOrEdit',
      },
    }))
  }
  finally {
    decrementLoading(store)
  }
}

export const getTeeSheetForAlertContent = (store, programId) => {
  return alertContentService
    .getPossibleContentCourses(programId)
    .then(list => {
      store.setState(prevState => ({
        ...prevState,
        AlertContent: {
          ...prevState.AlertContent,
          teeSheetForAlertContent: list,
        },
      }))
    })
    .catch(error => {
      throw error
    })
}

export const clearContentToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    AlertContent: {
      ...prevState.AlertContent,
      alertContentToEdit: null,
      teeSheetForAlertContent: [],
      currentTab: 'view',
    },
  }))
  refreshList(store);
}

export const refreshList = (store) => {
  const programId = store.state.AlertContent.selectedViewProgramId;
  const courseTeeSheetId = store.state.AlertContent.selectedViewCourseTeeSheetId;
  if (programId && programId > 0 && courseTeeSheetId && courseTeeSheetId > 0)
    getAlertContentList(store, { programId, courseTeeSheetId })
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearContentToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      AlertContent: { ...prevState.AlertContent, currentTab: tabName },
    }))
  }
}

export const getPossibleContentCourses = (store, programId) => {
  const { incrementLoading, decrementLoading } = store.state.app.actions
  incrementLoading(store)
  try {
    return alertContentService
      .getPossibleContentCourses(programId)
      .then(list => {
        store.setState(prevState => ({
          ...prevState,
          AlertContent: {
            ...prevState.AlertContent,
            teeSheetForSelect: list,
          },
        }))
      })
  }
  catch (error) {
    decrementLoading(store)
    throw error
  }
  finally {
    decrementLoading(store)
  }
}

export const getAlertContentPrograms = async store => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const defaultSelect = [{ name: "Select", "id": '' }]
  incrementLoading(store)
  try {
    const list = await programService.getProgramsForUser()
    const isSingleProgram = list.length === 1 ? true : false;
    const programList = isSingleProgram ? list : [...defaultSelect, ...list];
    var singleProgramId = 0;
    if (isSingleProgram) {
      singleProgramId = list[0].id;
    }
    store.setState(prevState => ({
      ...prevState,
      AlertContent: {
        ...prevState.AlertContent,
        availablePrograms: programList,
        isSingleProgram,
        singleProgramId
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const setSingleProgramId = (store, programId) => {
    store.setState(prevState => ({
      ...prevState,
      AlertContent: { ...prevState.AlertContent, singleProgramId: programId },
    }))
}

export const generateCancellationToken = () =>
  alertContentService.generateCancellationToken()
