import {createActions} from '../../../context'
import * as actions from './nonMemberDetails.actions'

export const createNonMemberDetails = store => {
  return {
    currentTab: 'memberships',
    reservationToEdit: undefined,
    reservation: {
      activeReservations: [],
      pastReservations: [],
      activeReservationsTotalItems:0,
      pastReservationsTotalItems:0,
    },
    responseMessage: {
      errorMessage : undefined
    },
    userInformation: undefined,
    creditCardDeleteError: undefined,
    actions: createActions(actions)(store),
  }
}
