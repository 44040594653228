import HttpService from '../../services/http.service'

const contentServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/alert`
const providerServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/provider`
const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`

const httpService = HttpService()
const ContentService = () => ({

  getAlertContentList: async (programId, teeSheetId) => {
    if (programId && teeSheetId) {
      const { data } = await httpService.get(`${contentServiceEndpoint}/list/${programId}?courseId=${teeSheetId}`)
      return data
    }
  },

  getContentById: async alertId => {
    const { data } = await httpService.get(
      `${contentServiceEndpoint}/${alertId}`,
    )

    return data
  },

  delete: async alertContentId => {
    try {
      const { data } = await httpService.delete(
        `${contentServiceEndpoint}/ ${alertContentId}`,
      )
      return data
    } catch (error) {
      throw error
    }
  },

  create: async contentToCreate => {
    const { data } = await httpService.post(
      `${contentServiceEndpoint}`,
      contentToCreate,
    )

    return data
  },

  update: async contentToUpdate => {
    const { data } = await httpService.put(
      `${contentServiceEndpoint}/${contentToUpdate.id}`,
      contentToUpdate,
    )

    return data
  },

  updateRank: async contentToUpdate => {
    const { data } = await httpService.put(
      `${contentServiceEndpoint}/UpdateAlertRankList`, contentToUpdate,
    )
    return data
  },


  getPossibleContentCourses: async programId => {
    const { data } = await httpService.get(`${courseServiceEndpoint}/getCoursesListFromProgram/${programId}`)
    return data
  },

  generateCancellationToken: () => httpService.generateCancellationToken(),
})

export default ContentService
