export const incrementLoading = store => {
  store.setState(prevState => ({
    ...prevState,
    app: {...prevState.app, isAppLoading: prevState.app.isAppLoading + 1},
  }))
}

export const decrementLoading = store => {
  store.setState(prevState => ({
    ...prevState,
    app: {...prevState.app, isAppLoading: prevState.app.isAppLoading - 1},
  }))
}

//toggleAppLoading
