import DashboardService from './dashboard.service'
import ProgramService from '../program/program.service'
import ReportsService from '../reports/reports.service'
import { DashboardFilterValues } from './dashboard.enums'

const dashboardService = DashboardService()
const programService = ProgramService()
const reportsService = ReportsService()

export const getAvailablePrograms = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  const defaultSelect=[{name:"Select","id":''}]
  incrementLoading(store)
  try {
    const list = await programService.getProgramsForUser()
    const programList=[...defaultSelect,...list]
    store.setState(prevState => ({
      ...prevState,
      Dashboard: {
        ...prevState.Dashboard,
          availablePrograms: programList
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getAvailableBillingPlans = async (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const list = await reportsService.getBillingPlansByProgram(programId ?? '')
    store.setState(prevState => ({
      ...prevState,
      Dashboard: {
        ...prevState.Dashboard,
        availableBillingPlans: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getMembershipsByProgram = async (store, program) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  const {programId, isReport} = program ? program : ''
  incrementLoading(store)
  try {
    const list = await dashboardService.getMembershipsByProgram(programId ?? '',isReport)
    store.setState(prevState => ({
      ...prevState,
      Dashboard: {
        ...prevState.Dashboard,
        availableMemberships: list,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const generateDashboardReport = async (
  store,
  { filters, freshSearch = false },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  //validate if billing plan selected details based on Membership
  if(filters.programId && filters.programId!="" && filters.programId >0 && filters.membershipId && filters.membershipId.length>0 && state.Dashboard.availableMemberships){
     // const membership = state.Dashboard.availableMemberships.find(m => m.id in filters.membershipId)
      let selectedMembershipBillingPlanId =[]

      filters.membershipId.forEach(function (membershipValue) {
      if (state.Dashboard.availableMemberships && membershipValue>0) {
        var data=state.Dashboard.availableMemberships.find(m => m.id == membershipValue);
        if(data && data.billingPlans)
        selectedMembershipBillingPlanId=selectedMembershipBillingPlanId.length>0?[...selectedMembershipBillingPlanId,...data.billingPlans]:data.billingPlans
      }
    });

    // User not selected the billing plan , so default set as all the billing plan id's
    if (selectedMembershipBillingPlanId && filters.billingPlanId && filters.billingPlanId.length == 0) {
        filters.billingPlanId=selectedMembershipBillingPlanId.map(a=>a.id)
      }
  }
  

  incrementLoading(store)

  try {
    store.setState(prevState => ({
      ...prevState,
      Dashboard: {
        ...prevState.Dashboard,
        dashboardReport: []
      },
    }))

    const  items  = await dashboardService.getDashboardDetails(filters)
    store.setState(prevState => ({
      ...prevState,
      Dashboard: {
        ...prevState.Dashboard,
        dashboardReport: items
      },
    }))
  } finally {
    decrementLoading(store)
  }
}
export const setBillingPlanId = (store, billingplanid) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, selectedBillingPlanid: billingplanid },
  }))
}

export const setIsRedirectToDashboard = (store, isRedirectToDashboard) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, isRedirectToDashboard: isRedirectToDashboard },
  }))
}

export const setDateFilters = (store, currentDateFilters) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, dashboardFilterDate: currentDateFilters },
  }))
}

export const setDashboardFilters = (store, dashboardfilters) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, dashboardfilters: dashboardfilters },
  }))
}

export const setCardExpiry = (store, cardExpiry) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, cardExpiry: cardExpiry },
  }))
}

export const setMembershipId = (store, membershipId) => {
  const state = store.state
  store.setState(prevState => ({
    ...prevState,
    Dashboard: { ...prevState.Dashboard, selectedMembershipId: membershipId },
  }))
}