import HttpService from '../../services/http.service'

const networkServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/network`

const programServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/program`

const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`

const httpService = HttpService()

const NetworkService = () => ({
  getNetworkList: async () => {
    const { data } = await httpService.get(
      `${networkServiceEndpoint}/list?filter.page.pageNumber=1&filter.page.pageSize=10`,
    )
    return (
      data.items &&
      data.items.map(p => ({ ...p, created_Date: new Date(p.created_Date) }))
    )
  },

  getNetworkForUpdate: async networkId => {
    const { data } = await httpService.get(
      `${networkServiceEndpoint}/${networkId}`,
    )

    return data
  },

  createNetwork: async networkToCreate => {
    const { data } = await httpService.post(
      `${networkServiceEndpoint}`,
      networkToCreate,
    )

    return data
  },

  updateNetwork: async networkToUpdate => {
    const { data } = await httpService.put(
      `${networkServiceEndpoint}/${networkToUpdate.id}`,
      networkToUpdate,
    )

    return data
  },

  getCoursesFromProgramMembership: async (programId, cancellationToken) => {
    const { data } = await httpService.get(`${programServiceEndpoint}/${programId}/courses`, { cancelToken: cancellationToken })
    return data
  },

  getCoursesPasses: async (courseId, cancellationToken) => {
    const { data } = await httpService.get(`${courseServiceEndpoint}/${courseId}/passes`, { cancelToken: cancellationToken })
    return data
  },

  getNetworkToViewPrograms: async id => {
    const { data } = await httpService.get(`${networkServiceEndpoint}/${id}/view`)
    return data
  },
})

export default NetworkService
