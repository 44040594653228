import {createActions} from '../../context'
import * as actions from './reservations.actions'

export const createReservations = store => {
  return {
    reservations: [],
    matchingUsers: [],
    selectedUser: undefined,
    thereWasASearch: false,
    actions: createActions(actions)(store),
    responseMessage: {
      errorMessage : undefined
    },
  }
}
