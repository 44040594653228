import HttpService from '../../services/http.service'

const programServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/program`
const providerServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/provider`
const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`

const httpService = HttpService()

const ProgramService = () => ({
  getProgramList: async () => {
    const {data} = await httpService.get(`${programServiceEndpoint}/list`)
    return data
  },

  getProgramsForUser: async () => {
    const {data} = await httpService.get(`${programServiceEndpoint}/forUser`)
    return data
  },

  getProgramById: async programId => {
    const {data} = await httpService.get(
      `${programServiceEndpoint}/${programId}`,
    )

    return data
  },

  create: async programToCreate => {
    const {data} = await httpService.post(
      `${programServiceEndpoint}`,
      programToCreate,
    )

    return data
  },

  update: async programToUpdate => {
    const {data} = await httpService.put(
      `${programServiceEndpoint}/${programToUpdate.id}`,
      programToUpdate,
    )

    return data
  },

  isProgramNameUsed: async programName => {
    const {data} = await httpService.get(
      `${programServiceEndpoint}/isNameUsed/${programName}`,
    )

    return data
  },

  checkPOSConnection: async (programPOSModel)=> {
    const {data} = await httpService.post(
      `${programServiceEndpoint}/checkPOSConnection`,
      programPOSModel,
    )
    return data;
  },

  isProgramSlugValid: async slug => {
    const {data} = await httpService.get(
      `${programServiceEndpoint}/isSlugValid/${slug}`,
    )

    return data
  },

  getCourses: async programId => {
    const {data} = await httpService.get(
      `${programServiceEndpoint}/${programId}/courses`,
    )

    return data
  },

  getPossibleProgramCourses: async providerId => {
    const {data} = await httpService.get(`${courseServiceEndpoint}/list/${providerId}`)

    return data
  },

  getCoursePasses: async (courseId, cancelToken) => {
    try {
      const {
        data,
      } = await httpService.get(
        `${courseServiceEndpoint}/${courseId}/passes`,
        {cancelToken},
      )

      return data
    } catch (error) {
      throw error
    }
  },

  getProviders: async () => {
    const {data} = await httpService.get(
      `${providerServiceEndpoint}`,
    )

    return data
  },


  generateCancellationToken: () => httpService.generateCancellationToken(),

  getShopListFromLS: async (courseId) => {
    const {data} = await httpService.get(`${programServiceEndpoint}/getShopsFromLS?courseId=${courseId}`)
    return data
  },

  getRegisterListFromLS: async (courseId) => {
    const {data} = await httpService.get(`${programServiceEndpoint}/getRegistersFromLS?courseId=${courseId}`)
    return data
  },

  getEmployeesListFromLS: async (courseId) => {
    const {data} = await httpService.get(`${programServiceEndpoint}/getEmployeesFromLS?courseId=${courseId}`)
    return data
  },
})

export default ProgramService
