import ReservationsService from './reservations.service'
import MemberService from '../members/members.service'
const reservationsService = ReservationsService()
const memberService = MemberService()

export const getNonMembersByEmail = async (store, reservationSearchModel) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    store.setState(prevState => ({
      ...prevState,
      Reservations: {
        ...prevState.Reservations,
        reservations: [],
        matchingUsers: [],
        selectedUser: undefined,
      },
      Member:{
        ...prevState.Member,
        email: '',
      },
      MemberDetails: {
        ...prevState.MemberDetails,
        purchaseMembershipfilters: {
          filters: {
            programId: 0,
            billingPlanId: 0,
            membershipId: 0
          }
        },
        availablePrograms: [],
        availableMemberships: [],
        isMember: false,
        isPurchase: false,
        isNationalNetworkMember: false,
      }
    }))
    const data = await reservationsService.getNonMemberList(reservationSearchModel)
    store.setState(prevState => ({
      ...prevState,
      Reservations: {
        ...prevState.Reservations,
        reservations: [],
        matchingUsers: data,
        selectedUser: undefined,
        thereWasASearch: true,
      },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const userUnblock = async (store, { unblockUserId, memberSearch,programId }) => {

  const { state } = store
  const { showNotification } = state.Notification.actions
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    return await memberService.userUnblock({unblockUserId,programId}).then((res) => {
      decrementLoading(store)
      if (memberSearch) {
        getNonMembersByEmail(store, memberSearch)
      }
      setTimeout(() => {
        showNotification({
          type: 'success',
          message: 'Please contact Supreme Golf support to block this user',
        })
      }, 2000);
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: "The User will still be blocked",
    })
    throw error
  }
}

export const selectNonMemberToView = async (store, nonMember) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    store.setState(prevState => ({
      ...prevState,
      Reservations: {
        ...prevState.Reservations,
        selectedUser: undefined,
      },
    }))
    const data = await reservationsService.getNonMemberReservations(
      nonMember.programId,
      nonMember.id,
    )
    store.setState(prevState => ({
      ...prevState,
      Reservations: {
        ...prevState.Reservations,
        reservations: data,
        selectedUser: nonMember,
      },
    }))
    decrementLoading(store)
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const clearNonMemberToView = async store => {
  store.setState(prevState => ({
    ...prevState,
    Reservations: {
      ...prevState.Reservations,
      reservations: [],
      matchingUsers: [],
      selectedUser: undefined,
      thereWasASearch: false,
    },
    NonMemberDetails: {
      ...prevState.NonMemberDetails,
      responseMessage: {}
    },
  }))
}
