import {createActions} from '../../context'
import * as actions from './network.actions'

export const createNetwork = store => {
  return {
    networks: [],
    networkToViewPrograms: undefined,
    currentTab: 'view',
    networkToEdit: undefined,
    actions: createActions(actions)(store),
  }
}
