import axios from 'axios'

import { USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY, SITE_UNDER_MAINTENANCE } from '../utils/userTokenKey'

const loginEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/auth/token`
const refreshTokenEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/auth/tokenFromRefreshToken`

const AuthService = () => ({
  singIn: async (username, password) => {
    const requestBody = {
      username,
      password,
    }
    const { data } = await axios.post(loginEndpoint, requestBody)
    const { access_token, refresh_token } = data

    localStorage.setItem(USER_TOKEN_KEY, access_token)
    localStorage.setItem(USER_REFRESH_TOKEN_KEY, refresh_token)

    return access_token
  },

  refreshToken: async () => {
    const refreshToken = localStorage.getItem(USER_REFRESH_TOKEN_KEY)
    const requestBody = {
      refreshToken,
    }

    try {
      const { data } = await axios.post(refreshTokenEndpoint, requestBody)
      const { access_token, refresh_token } = data

      localStorage.setItem(USER_TOKEN_KEY, access_token)
      localStorage.setItem(USER_REFRESH_TOKEN_KEY, refresh_token)

      return access_token
    } catch (error) {
      localStorage.setItem(USER_TOKEN_KEY, null)
      localStorage.setItem(USER_REFRESH_TOKEN_KEY, null)
      // the refresh failed, then I go to login
      window.history.pushState('/login')
      throw error
    }
  },

  isAuthenticated: () => {
    return (
      // eslint-disable-next-line eqeqeq
      localStorage.getItem(USER_TOKEN_KEY) != 'null' &&
      localStorage.getItem(USER_TOKEN_KEY) != null
    )
  },

  validateSite: async () => {
    let siteLoaded = "";
    await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/maintenance`)
      .then(data => {
        siteLoaded = "true";
      })
      .catch(error => {
        if (error.response && (error.response.status === 503)) {
          console.log("validateSiteError", error.response.status)
          siteLoaded = "";
        }
      });
    if (siteLoaded === "") {
      localStorage.setItem(SITE_UNDER_MAINTENANCE, "True")
      if (!window.location.href.includes('/undermaintenance'))
        window.location.href = '/undermaintenance'
      return false;
    }
    else {
      localStorage.setItem(SITE_UNDER_MAINTENANCE, "False")
    }
    return true;
  }

})

export default AuthService
