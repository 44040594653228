import axios from 'axios'
import HttpService from './http.service'

const userDataEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/user/userData`
const tokenValidationEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/user/validateInvitation`
const completeRegistrationEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/user/completeRegistration`
const zipcodeEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/ZipCode/validate`
const passwordValidateEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/Validate/validatePassword`
const resetPasswordEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/User/resetPasswordConfirmation`
const resetPasswordValidation = `${process.env.REACT_APP_API_ENDPOINT}/User/resetPasswordValidation`
const sendPasswordResetEmail = `${process.env.REACT_APP_API_ENDPOINT}/User/resetPassword`

const UserService = () => ({
  getProfile: async () => {
    const httpService = HttpService()
    const {data} = await httpService.get(userDataEndpoint)
    return data
  },

  validateInvitationToken: async token => {
    const {data} = await axios.get(`${tokenValidationEndpoint}/${token}`)
    const {id} = data
    return id
  },
  validateZipCode: async (zipCode,countryISO, programId) => {
       const {data} = await axios.get(`${zipcodeEndpoint}?zip_code=${zipCode}&countryISO=${countryISO}&programId=${programId}`)
       return data
  },
  completeRegistration: async profileData => {
    const {data} = await axios.post(completeRegistrationEndpoint, profileData)
    return data
  },
  validatePassword: async (password, programId) => {   
    const {data} = await axios.post(`${passwordValidateEndpoint}?programId=${programId}`, {password})    
    return data
  },
  passwordReset:async ({userId,token, password}) => {
    const requestBody = { userId, token, password };
    const {data} = await axios.post(`${resetPasswordEndpoint}`, requestBody)    
    return data
  },
  resetPasswordValidation:async (token) => {
  const {data} = await axios.get(`${resetPasswordValidation}/${token}`)    
  return data
  },
  sendResetPasswordEmail:async (email) => {
    const {data} = await axios.post(`${sendPasswordResetEmail}/${email}`)    
    return data
    }
})

export default UserService
