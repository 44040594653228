import {createActions} from '../../context'
import * as actions from './message.actions'

export const createMessage = store => {
  return {
    messages: [],
    messageToViewCoursePolicies: undefined,
    currentTab: 'view',
    messageToEdit: {coursePolicies: [],
                    courseBookingRules: []},
                    currentSGCourses: [],
    actions: createActions(actions)(store),
  }
}