import React from 'react'
import {Toast} from 'react-bootstrap'
import PropTypes from 'prop-types'
import './notification.scss'

function Notification({message, hideNotification, isShow, type}) {
  return (
    <Toast
      onClose={() => hideNotification()}
      show={isShow}  style={{zIndex:1}}
      delay={3000}
      autohide
    >
      {/* <Toast.Header>
        <strong className="mr-auto">Notification</strong>
      </Toast.Header> */}
      <Toast.Body className={type}>
        {' '}
        {type === 'success' ? (
          <i className="material-icons success-icon">check_circle</i>
        ) : (
          <i className="material-icons error-icon">error</i>
        )}
        {message}
      </Toast.Body>
    </Toast>
  )
}

Notification.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  hideNotification: PropTypes.func.isRequired,
}

export default Notification
