import HttpService from '../../services/http.service'

const membershipServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership`
const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`
const membershipBillingPlanServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership/billingPlan`
const programServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/program`
const billingPlanServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membershipBillingPlan`

const httpService = HttpService()

const MembershipService = () => ({
  getMembershipList: async programId => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/list/${programId}`,
    )
    return data
  },

  getPossibleMembershipUpgradeSources: async (
    programId,
    courseId,
    membershipId,
  ) => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/availableMembershipsForUpgrading?programId=${programId}&courseId=${courseId}${
        membershipId ? '&membershipId=' + membershipId : ''
      }`,
    )
    return data
  },

  getMembershipById: async membershipId => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/${membershipId}`,
    )

    return data
  },

  create: async membershipToCreate => {
    const formData = new FormData()
    var {banner, logo, ...membership} = membershipToCreate

    formData.append('', JSON.stringify(membership))
    formData.append('banner', banner)
    formData.append('logo', logo)

    const {data} = await httpService.post(
      `${membershipServiceEndpoint}`,
      formData,

      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )

    return data
  },

  update: async membershipToUpdate => {
    const formData = new FormData()
    var {banner, logo, id, ...membership} = membershipToUpdate

    formData.append('', JSON.stringify(membership))
    if (!!banner) {
      formData.append('banner', banner)
    }
    if (!!logo) {
      formData.append('logo', logo)
    }

    const {data} = await httpService.put(
      `${membershipServiceEndpoint}/${id}`,
      formData,
    )

    return data
  },

  //1419
  updateBillingPlan: async updatedBillingPlan => {

 const {data} = await httpService.put(
      `${membershipBillingPlanServiceEndpoint}/${updatedBillingPlan.id}`,
      updatedBillingPlan,
    )

    return data
  },
  //1419
 
  isMembershipNameUsed: async (membershipName, programId) => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/isNameUsed/${programId}/${membershipName}`,
    )

    return data
  },

  updateWithReciprocities: async (membershipId, reciprocityList) => {
    const {data} = await httpService.put(
      `${membershipServiceEndpoint}/${membershipId}/membershipReciprocityCourses`,
      reciprocityList,
    )

    return data
  },

  getMembershipReciprocities: async membershipId => {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/${membershipId}/membershipReciprocityCourses`,
    )

    return data
  },

  getPossibleReciprocalCourses: async () => {
    const {data} = await httpService.get(`${courseServiceEndpoint}/list/reciprocal`)

    return data
  },
  getPlanEffectiveDates: async(frequencyId,billingDateId,billingMonth,billingDay,minimumBillingCyclesToCancel)=>{
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/GetPlanEffectiveDates?FrequencyId=${frequencyId}&BillingDateId=${billingDateId}
      &BillingMonth=${billingMonth}&BillingDay=${billingDay}&MinimumBillingCyclesToCancel=${minimumBillingCyclesToCancel}`,
    )   
    return data
  },
  
  checkPOSIntegration: async (currentGlobalProgramId) => {
    const {data} = await httpService.get(`${membershipServiceEndpoint}/checkPOSIntegration?programId=${currentGlobalProgramId}`)
    return data
  },

  GetPOSFieldData: async (providerId, courseId)=>{
    const {data} = await httpService.get(`${membershipServiceEndpoint}/GetPOSFieldData?providerId=${providerId}&courseId=${courseId}`)
    return data
  },
  
  getSGNetworkPrograms: async()  => {
    const {data} = await httpService.get(
      `${programServiceEndpoint}/getSGNetworkPrograms`,
    )

    return data
  },

  getSGNetworkMembershipsByProgram: async (programId) => {
    const { data } = await httpService.get(
      `${membershipServiceEndpoint}/getActiveMembershipByProgram?programId=${programId}`,
    )

    return data
  },

  getSGNetworkBillingPlans: async (programId) => {
    if (programId) {
      const { data } = await httpService.get(
        `${billingPlanServiceEndpoint}?programId=${programId}`,
      )
      
      return data
    }
  },
})

export default MembershipService
