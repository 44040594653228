import * as queryString from 'query-string'

import HttpService from '../../services/http.service'

const noshowChargeEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/noShowCharge`
const reservationEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/reservation`
const refundAndCancelEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/refundAndCancel`
const customerEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/customer`
const paymentEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/payment`

const httpService = HttpService()

const ReservationsService = () => ({
  getNonMemberList: async reservationSearchModel => {
    const {data} = await httpService.post(
      `${reservationEndpoint}/nonmember/email`,reservationSearchModel
    )
    return data
  },

  getNonMemberReservations: async (programId, userId, query) => {
    let _url 
    if(query!=="" || query!==undefined){
      _url= `${reservationEndpoint}/nonmember?userId=${userId}&programId=${programId}&${queryString.stringify(
        query,
      )}`
    }else{
      _url= `${reservationEndpoint}/nonmember?userId=${userId}&programId=${programId}`
    }
    const {data} = await httpService.get(
     _url,
    )
    return data
  },

  getMemberReservations: async (query, options) => {
    const {data} = await httpService.get(
      `${reservationEndpoint}?${queryString.stringify(query)}`,
      options,
    )

    return data
  },

  getReservation: async (userId,programId,id) => {
    const {data} = await httpService.get(
      `${reservationEndpoint}/nonmemberdetail/${id}?userId=${userId}&programId=${programId}`,
    )

    return data
  },

  validateMember: async memberEmailInAffiliate => {
    const email =memberEmailInAffiliate.email
    const {data} = await httpService.post(
      `${customerEndpoint}/validateuser`, memberEmailInAffiliate)

    return data
  },

  chargeNoShow: async noShowChargeModel => {
    const noShowChargePayment = noShowChargeModel.noShowChargePayment
    const {data} = await httpService.post(
      `${noshowChargeEndpoint}/pay`,
      noShowChargePayment,
    )
    return data
  },
  cancelBooking: async reservationCancelModel => {
    await httpService.post(
      `${refundAndCancelEndpoint}/reservation`,
      reservationCancelModel,
    )
  },

  cancelAndRefundBooking: async reservationCancelModel => {
    await httpService.post(
      `${refundAndCancelEndpoint}/reservationrefund`,
      reservationCancelModel,
    )
  },

  getUserInformation: async (userId, programId) => {
    const {data} = await httpService.get(
      `${customerEndpoint}/nonmembercustomerinformation/${userId}?programId=${programId}`,
    )

    return data
  },

  updateUserInformation: async (userId, programId, newUserInformation) => {
    await httpService.put(
      `${customerEndpoint}/updateuserdetails/nonmember?programId=${programId}`,
      {
        userId,
        ...newUserInformation,
      },
    )
  },

  deleteTestCreditCard: async (memberId,creditCardId) => {
    const {data} = await httpService.delete(
      `${paymentEndpoint}/${memberId}/deletetestcreditcards/${creditCardId}`,
      )

    return data
  },

  getCheckoutData: async (id,qty,programId) => {
    const requestData = {
      programId: programId,
      reservationId:id,
      newQty: qty
    };
    const {data} = await httpService.post(
      `${reservationEndpoint}/modifyreservationprepare`, requestData
    )
    return data
  },

  
  getSelectedRefundCheckoutData: async (id,qty,programId,isDeposit, isBookingFee, isAdvanceReservationFee ) => {
    const requestData = {
      programId: programId,
      reservationId:id,
      newQty: qty,
      isDeposit, 
      isBookingFee, 
      isAdvanceReservationFee 
    };
    const {data} = await httpService.post(
      `${reservationEndpoint}/modifySelectedreservationprepare`, requestData
    )
    return data
  },

  modifyBooking: async (programId,id, previousQty, qty,isDeposit,
    isBookingFee,
    isAdvanceReservationFee,
    creditCardId) => {
    const reservationData = {
      programId: programId,
      reservationId:id,
      previousQty: previousQty,
      newQty: qty,
      isDeposit,
      isBookingFee,
      isAdvanceReservationFee,
      creditCardId: creditCardId
    };
    const {data} = await httpService.post(
      `${reservationEndpoint}/modifyreservation`, reservationData
    )
    return data
  },
})

export default ReservationsService
