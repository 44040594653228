import AuthService from '../../services/auth.service'
import {USER_TOKEN_KEY, USER_REFRESH_TOKEN_KEY,SITE_UNDER_MAINTENANCE} from '../../utils/userTokenKey'
import UserService from '../../services/user.service'

import {NOT_FOUND, BAD_REQUEST} from '../../utils/status-code'

import appHistory from '../../utils/router'

const authService = AuthService()
const userService = UserService()

export const setLoggedUser = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)

  try {
    const user = await userService.getProfile()

    decrementLoading(store)

    store.setState(prevState => ({
      ...prevState,
      user: {...prevState.user, loggedUser: {...user}},
    }))
  } catch (error) {
    decrementLoading(store)
    throw error
  }
}

export const   validateSite = async () => {
  await authService.validateSite()
}


export const login = async (store, {email, password}) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    await authService.singIn(email, password)
    decrementLoading(store)

    store.setState(prevState => ({
      ...prevState,
      user: {...prevState.user, isAuthenticated: authService.isAuthenticated()},
    }))
  } catch (err) {
    decrementLoading(store)
    throw err
  }
}

export const logout = store => {
  localStorage.setItem(USER_TOKEN_KEY, null)
  localStorage.setItem(USER_REFRESH_TOKEN_KEY, null)
  localStorage.setItem(SITE_UNDER_MAINTENANCE, "False")
  if (localStorage.getItem("reservationReportFilters") != null) {
    localStorage.removeItem("reservationReportFilters");
  }
  if (localStorage.getItem("memberReportFilters") != null) {
    localStorage.removeItem("memberReportFilters");
  }
  if (localStorage.getItem("paymentReportFilters") != null) {
    localStorage.removeItem("paymentReportFilters");
  }
  store.setState(prevState => ({
    ...prevState,
    user: {
      ...prevState.user,
      isAuthenticated: authService.isAuthenticated(),
      loggedUser: null,
    },
    MemberDetails: {...prevState.MemberDetails, currentStep: 1, purchaseMembershipfilters:{
      filters:{
        programId:0,
        billingPlanId:0,
        membershipId:0,
        scheduleId:0
      }
    },
    availablePrograms: [],
    availableMemberships: [],
    isMember: false },
    Member: {...prevState.Member, currentTab: 'view',  addMemberStepValue: undefined,
    addMemberInfo: undefined, clickedAddMember: false, email: ''}
  }))
}

export const setInvitedUser = async (store, invitationToken) => {
  const state = store.state
  const showNotification = state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)

  try {
    return await userService
      .validateInvitationToken(invitationToken)
      .then(userId => {
        store.setState(prevState => ({
          ...prevState,
          user: {...prevState.user, invitedUserId: userId},
        }))
        decrementLoading(store)
      })
  } catch (error) {
    const {status} = error
    store.setState(prevState => ({
      ...prevState,
      user: {...prevState.user, invitedUserId: undefined},
    }))

    decrementLoading(store)

    appHistory.push('/login')

    switch (status) {
      case NOT_FOUND:
        showNotification({
          type: 'error',
          message: 'This invitation token was not found',
        })
        break
      case BAD_REQUEST:
        showNotification({
          type: 'error',
          message: 'This invitation token is invalid',
        })
        break
      default:
        showNotification({
          type: 'error',
          message: 'There was an error validating the invitation token',
        })
    }
    throw error
  }
}

export const completeRegistration = async (store, profileData) => {
  const state = store.state
  const showNotification = state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)

  return await userService
    .completeRegistration(profileData)
    .then(() => {
      decrementLoading(store)
      store.setState({
        ...state,
        user: {...state.user, invitedUserId: undefined},
      })
      showNotification({
        type: 'success',
        message: 'Your registration was successful, please sign in to continue',
      })
      appHistory.push('/login')
    })
    .catch(error => {
      decrementLoading(store)

      showNotification({
        type: 'error',
        message: 'There was an error completing your registration',
      })

      throw error
    })
}

export const cancelRegistration = store => {
  const state = store.state

  store.setState({...state, user: {...state.user, invitedUserId: undefined}})

  appHistory.push('/login')
}

export const resetPassword = async (store, {userId,token,password}) => {
  const state = store.state
  const showNotification = state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  return await userService
    .passwordReset({userId,token,password})
    .then(() => {
      showNotification({type: 'success',message: 'Your password reset was successful, please sign in to continue'})
      appHistory.push('/login')
      decrementLoading(store)
    })
    .catch(error => {
      showNotification({type: 'error',message: 'There was an error resetting your password'})
      appHistory.push('/login')
      decrementLoading(store)
      throw error
    })
}

export const getUserIdBasedOnToken = async (store, token) => {
  const state = store.state
  const showNotification = state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions
  let id;
  incrementLoading(store)
  await userService.resetPasswordValidation(token).then((userId) => {
  id = userId;
  decrementLoading(store)
  })
  .catch(error => {
    showNotification({type: 'error',message: 'This reset link is no longer valid. Please request for a new password reset link from admin.letsgo.golf/sign-in (linkedin)'});
    appHistory.push('/login')
    decrementLoading(store)
    throw error
  })
  return id;
}

export const sendResetPasswordEmail = async (store, email) => {
  const state = store.state
  const showNotification = state.Notification.actions.showNotification
  const {incrementLoading, decrementLoading} = state.app.actions
  incrementLoading(store)
  return await userService.sendResetPasswordEmail(email)
  .then(() => {
    showNotification({type: 'success',message: 'An email has been sent to your email address regarding your password reset'})
    appHistory.push('/login')
    decrementLoading(store)
  })    
  .catch(error => {
    showNotification({type: 'error',message: 'There was an error resetting your password'})
    decrementLoading(store)
    throw error
  })
}