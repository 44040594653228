import React, {useEffect} from 'react'
import {Router} from 'react-router-dom'
import {PropTypes} from 'prop-types'
import {connect} from './context'

import appHistory from './utils/router.js'
import AppSpinner from './views/Spinner'
import './app.scss'
import AuthService from './services/auth.service'

const loadAuthenticatedApp = () => import('./AuthenticatedApp')
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)
const UnAuthenticatedApp = React.lazy(() => import('./UnAuthenticatedApp'))

const authService = AuthService()

function App({isAuthenticated, isAppLoading}) {
  useEffect(() => {
    // pre-load the authenticated side in the background while the user's
    // filling out the login form.
    authService.validateSite()
    loadAuthenticatedApp()
  }, [])

  return (
    <>
      <Router history={appHistory}>
        <React.Suspense fallback={<AppSpinner></AppSpinner>}>
          {authService.isAuthenticated() ? (
            <AuthenticatedApp />
          ) : (
            <UnAuthenticatedApp />
          )}
        </React.Suspense>
      </Router>

      {isAppLoading > 0 && <AppSpinner />}
    </>
  )
}

App.propTypes = {
  isAuthenticated: PropTypes.bool,
}

const mapStateToProps = ({user, app}) => ({
  isAuthenticated: user && user.isAuthenticated,
  isAppLoading: app && app.isAppLoading,
})

export default connect(mapStateToProps, App)
