import HttpService from '../../services/http.service'
const paymentProcessorServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/PaymentProcessors`

const httpService = HttpService()

const PaymentsService = () => ({
    getPaymentProcessors: async () => {
        const { data } = await httpService.get(
            `${paymentProcessorServiceEndpoint}/list`,
        )

        return data
    },

    getPaymentProcessorConfiguration: async (programId) => {
        try {
            const { data } = await httpService.get(
                `${paymentProcessorServiceEndpoint}/${programId}/configurations`,
            )
            return data
        } catch (error) {
            return null
        }
    },

    hasProgramConfiguredPaymentProcessors: async (programId) => {
        try {
            const { data } = await httpService.get(`${paymentProcessorServiceEndpoint}/${programId}/isconfigured`)
            return Boolean(data);
        } catch (error) {
            return false;
        }

    },

    postPaymentProcessorConfiguration: async (programId, configuration) => {
        const { data } = await httpService.post(
            `${paymentProcessorServiceEndpoint}/${programId}/configurations`, configuration
        )
        return data
    },
    deletePaymentProcessorConfiguration: async (programId, legacyProcessorConfigToDelete) => {
        const { data } = await httpService.post(
            `${paymentProcessorServiceEndpoint}/${programId}/configurations`, legacyProcessorConfigToDelete
        )
        return data
    },
})

export default PaymentsService
