import {createActions} from '../context/index'
import * as userActions from './actions/user.actions'
import * as appActions from './actions/app.actions'
import AuthService from '../services/auth.service'
import {createProgram} from '../components/program/program.store'
import {createPayments} from '../components/payments/payments.store'
import {createMembership} from '../components/membership/membership.store'
import {createNotification} from '../views/Notification'
import {createManageUsers} from '../components/manage-users/manageUsers.store'
import {createMigration} from '../components/migration/migration.store'
import {createMember} from '../components/members/members.store'
import {createNonMemberDetails} from '../components/reservations/non-member-details/nonMemberDetails.store'
import {createMemberDetails} from '../components/members/member-details/memberDetails.store'
import {createEmployees} from '../components/employees/employees.store'
import {createUpgrades} from '../components/upgrades/upgrades.store'
import {createNetwork} from '../components/network/network.store'
import {createReservations} from '../components/reservations/reservations.store'
import {createReports} from '../components/reports/reports.store'
import {createDashboard} from '../components/dashboard/dashboard.store'
import {createSupport} from '../components/support/support.store'
import {createMessage} from '../components/message/message.store'
import {createAlertContent} from '../components/alert-content/alert-content.store'


const authService = AuthService()

export const createAuth = store => ({
  loggedUser: undefined,
  isAuthenticated: authService.isAuthenticated(),
  invitedUserId: undefined,
  actions: createActions(userActions)(store),
})

export const createApp = store => ({
  isAppLoading: 0,
  actions: createActions(appActions)(store),
})

export const createStore = context => ({
  ...context.state,
  ManageUsers: createManageUsers(context),
  Program: createProgram(context),
  Payments: createPayments(context),
  Membership: createMembership(context),
  Notification: createNotification(context),
  Migration: createMigration(context),
  Member: createMember(context),
  NonMemberDetails: createNonMemberDetails(context),
  Reservations: createReservations(context),
  Reports: createReports(context),
  Dashboard:createDashboard(context),
  MemberDetails: createMemberDetails(context),
  Employees: createEmployees(context),
  Upgrades: createUpgrades(context),
  Network: createNetwork(context),
  Support: createSupport(context),
  AlertContent:createAlertContent(context),
  user: createAuth(context),
  app: createApp(context),
  Message: createMessage(context),
})
