import HttpService from '../../services/http.service'

const employeeServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/employee`
const httpService = HttpService()

const EmployeesService = () => ({
  getEmployees: async ({filter, page}) => {
    const {data} = await httpService.get(
      `${employeeServiceEndpoint}?filter.programId=${filter.programId}` +
        `&filter.courseId=${filter.courseId}&page.pageNumber=${page.pageNumber}` +
        `&page.pageSize=${page.pageSize}`,
    )
    return data
  },

  getEmployeeById: async employeeId => {
    const {data} = await httpService.get(
      `${employeeServiceEndpoint}/${employeeId}`,
    )

    return data
  },

  create: async employeeToCreate => {
    const {data} = await httpService.post(
      `${employeeServiceEndpoint}`,
      employeeToCreate,
    )

    return data
  },

  update: async employeeToUpdate => {
    const {data} = await httpService.put(
      `${employeeServiceEndpoint}/${employeeToUpdate.id}`,
      employeeToUpdate,
    )

    return data
  },

  isEmployeeNameValid: async employee => {
    const {data} = await httpService.post(
      `${employeeServiceEndpoint}/isFullNameValid`,
      employee,
    )

    return data
  },
})

export default EmployeesService
