export const DashboardFilterValues = Object.freeze({
  Yesterday: 1,
  Last_7_Days: 2,
  Last_14_Days: 3,
  Last_30_Days: 4,
  Last_90_Days: 5,
  Last_180_Days: 6,
  Month: 7,
  Year: 8,
  Custom: 9,
})
export const DashboardFilterText = Object.freeze({
  Yesterday: "Yesterday",
  Last_7_Days: "Last 7 Days",
  Last_14_Days: "Last 14 Days",
  Last_30_Days: "Last 30 Days",
  Last_90_Days: "Last 90 Days",
  Last_180_Days: "Last 180 Days",
  Month: "Month To Date",
  Year: "Year To Date",
  Custom: "Custom",
})
