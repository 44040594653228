import {createActions} from '../../context'
import * as actions from './employees.actions'

export const createEmployees = store => {
  return {
    employees: [],
    totalItems: 0,
    employeToEdit: undefined,
    currentTab: 'view',
    availablePrograms: [],
    availableCourses: [],
    actions: createActions(actions)(store),
  }
}
