import {createActions} from '../../../context'
import * as actions from './memberDetails.actions'
import {PurchaseValidationStatus} from '../purchase-validation-status.enum'

import {PurchaseStatusEnum} from '../member-details/memberships-tab/membership-detail/purchase-status.enum'
export const createMemberDetails = store => {
  return {
    membershipList: [],
    membershipHistoryList:[],
    membershipToEdit: undefined,
    availablePrograms: [],
    availableMembers: [],
    purchaseMembershipfilters:{
      filters:{
        programId:0,
        billingPlanId:0,
        membershipId:0,
        scheduleId:0
      },
      memberId:''
    },
    availableMemberships:[],
    selectedBillingPlanid:[],
    selectedMembershipId:[],
    suspendedMembershipInfo: undefined,
    memberToEdit: undefined,
    currentTab: 'memberships',
    savedCreditCards: [],
    isLoadingSavedCreditCard: false,
    isLoadingReceipts: false,
    creditCardDeleteError: undefined,
    receipts: {items: [], count: 0},
    bookingToEdit: undefined,
    bookings: {
      activeBookings: [],
      pastBookings: [],
      activeBookingsTotalItems:0,
      pastBookingsTotalItems:0
    },
    userInformation: undefined,
    employees: [],
    currentMembership: undefined,
    purchaseMembership: {  billingPlanSummary: undefined, isLoadingInvoice: false, targetBillingPlanId: undefined, info:{id:undefined}, scheduleId: undefined } ,
    upgradeMembership: {
      invoice: undefined,
      isLoadingInvoice: false,
      targetMembership: undefined,
      isLoadingTargetMembership: false,
      targetBillingPlanId: undefined,
      scheduleId: undefined 
    },
    purchaseValidationStatus: PurchaseValidationStatus.DEFAULT,
    upgradeInfo: undefined,
    showCheckoutInformation: false,
    isDownloadReceipt: false,
    actions: createActions(actions)(store),
    selectedCreditCardId: undefined,
    purchaseState: PurchaseStatusEnum.READY,
    paymentError: undefined,
    purchaseConfirmationData: undefined,
    isAddCardVisible: false,
    isDeletingCreditCard: false,
    selectedEmployeeId: undefined,
    isLoadingEmployees: false,
    termsAndConditions: undefined,
    isLoadingTermsAndConditions: false,
    currentStep: 1,
    isCustomerDataMissing:false,   
    customerProgramDetail: undefined,
    isUserAlreadyHaveMembershipDetails: false,
  }
}
