import SupportService from './support.service'

const supportService = SupportService()

export const createSupportRequest = async (store, supportRequest) => {
    const {state} = store
    const {showNotification} = state.Notification.actions
    const {incrementLoading, decrementLoading} = state.app.actions
    const programId = state.Program.currentGlobalProgramId
    incrementLoading(store)
    try {
      await supportService.createSupportRequest(supportRequest).then((res) => {
      return res===""?true:false
      })
      decrementLoading(store)
    } catch (error) {
      decrementLoading(store)
      showNotification({
        type: 'error',
        message: 'Support request failed!',
      })
      return false
      throw error
    }
  }