import ProgramService from './program.service'

const programService = ProgramService()

export const getProgramList = store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  return programService
    .getProgramList()
    .then(list => {
      store.setState(prevState => ({
        ...prevState,
        Program: {
          ...prevState.Program,
          programs: list,
          currentTab: 'view',
          programToEdit: undefined,
        },
        Member:{
          ...prevState.Member,
          email: '',
        }
      }))

      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const getProgramsForUser = store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  return programService
    .getProgramsForUser()
    .then(list => {
      decrementLoading(store)
      const currentGlobalProgramId = list && list[0] ? list[0].id : 1
      setCurrentGlobalProgram(store, currentGlobalProgramId)

      store.setState(prevState => ({
        ...prevState,
        Program: {
          ...prevState.Program,
          programsForSelect: list,
          currentTab: 'view',
          programToEdit: undefined,
        },
      }))
    })
    .catch(error => {
      decrementLoading(store)

      throw error
    })
}

export const createProgram = async (store, programToCreate) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  const showNotification = state.Notification.actions.showNotification

  const programToCreateModified = {
    ...programToCreate,
    emails: [programToCreate.email],
  }
  incrementLoading(store)

  try {
    await programService.create(programToCreateModified)
    await getProgramList(store)
    showNotification({
      type: 'success',
      message: 'Program created successfully!',
    })
    decrementLoading(store)
    await getProgramsForUser(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Program creation failed!',
    })
    throw error
  }
}

export const updateProgram = async (store, programToUpdate) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  const showNotification = state.Notification.actions.showNotification
  const globalProgramId = state.Program.currentGlobalProgramId

  incrementLoading(store)

  try {
    await programService.update(programToUpdate)
    if (globalProgramId === programToUpdate.id) {
      setCurrentGlobalProgram(store, globalProgramId)
    }
    await getProgramList(store)
    showNotification({
      type: 'success',
      message: 'Program updated successfully',
    })
    await getProgramsForUser(store)
    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Program update failed!',
    })
    throw error
  }
}

export const selectProgramToEdit = async (store, programToEditId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try{
    const programToEdit = await programService.getProgramById(programToEditId)
    const possibleProgramCourses = await programService.getPossibleProgramCourses(programToEdit.providerId)
          
    store.setState(prevState => ({
      ...prevState,
      Program: {
        ...prevState.Program,
        programToEdit,
        possibleProgramCourses,
        currentTab: 'createOrEdit',
      },
    }))      
  }
  finally {
    decrementLoading(store)
  }
}

export const clearProgramToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    Program: {
      ...prevState.Program,
      programToEdit: undefined,
      currentTab: 'view',
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearProgramToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      Program: {...prevState.Program, currentTab: tabName},
    }))
  }
}

export const setCurrentGlobalProgram = (store, programId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  programService
    .getProgramById(programId)
    .then(globalProgram => {
      store.setState(prevState => ({
        ...prevState,
        Program: {
          ...prevState.Program,
          globalProgram,
          currentGlobalProgramId: +programId,
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)

      throw error
    })

  store.setState(prevState => ({
    ...prevState,
    Program: {...prevState.Program, currentGlobalProgramId: +programId},
  }))
}

export const getCourses = store => {
  const state = store.state
  const {currentGlobalProgramId} = state.Program
  const {incrementLoading, decrementLoading} = state.app.actions

  if (currentGlobalProgramId) {
    incrementLoading(store)

    return programService
      .getCourses(currentGlobalProgramId)
      .then(list => {
        store.setState(prevState => ({
          ...prevState,
          Program: {
            ...prevState.Program,
            currentCourses: list,
          },
        }))

        decrementLoading(store)
      })
      .catch(error => {
        decrementLoading(store)
        throw error
      })
  }
}

export const getCoursePasses = async (store, {courseId, cancellationToken}) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    if (courseId) {
      const passes = await programService.getCoursePasses(
        courseId,
        cancellationToken,
      )
      decrementLoading(store)

      return passes
    }
  } catch (error) {
    decrementLoading(store)

    throw error
  }
}

export const getPossibleProgramCourses = (store, { providerId} ) => {

  return programService
    .getPossibleProgramCourses(providerId)
    .then(list => {
      store.setState(prevState => ({
        ...prevState,
        Program: {
          ...prevState.Program,
          possibleProgramCourses: list,
        },
      }))
    })
    .catch(error => {
      throw error
    })
}

export const getProviders = store => {
  const state = store.state

  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)

  return programService
    .getProviders()
    .then(list => {
      store.setState(prevState => ({
        ...prevState,
        Program: {
          ...prevState.Program,
          providers: list,
        },
      }))

      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const generateCancellationToken = () =>
  programService.generateCancellationToken()

  export const getShopList = (store, courseId) => {
    const state = store.state
  
    const {incrementLoading, decrementLoading} = state.app.actions
  
    incrementLoading(store)
  
    return programService
      .getShopListFromLS(courseId)
      .then(list => {
        store.setState(prevState => ({
          ...prevState,
          Program: {
            ...prevState.Program,
            shopList: list,
          },
        }))
  
        decrementLoading(store)
      })
      .catch(error => {
        decrementLoading(store)
        throw error
      })
  }

  export const getRegisterList = (store, courseId) => {
    const state = store.state
  
    const {incrementLoading, decrementLoading} = state.app.actions
  
    incrementLoading(store)
  
    return programService
      .getRegisterListFromLS(courseId)
      .then(list => {
        store.setState(prevState => ({
          ...prevState,
          Program: {
            ...prevState.Program,
            registerList: list,
          },
        }))
  
        decrementLoading(store)
      })
      .catch(error => {
        decrementLoading(store)
        throw error
      })
  }

  export const getEmployeeList = (store, courseId) => {
    const state = store.state
  
    const {incrementLoading, decrementLoading} = state.app.actions
  
    incrementLoading(store)
  
    return programService
      .getEmployeesListFromLS(courseId)
      .then(list => {
        store.setState(prevState => ({
          ...prevState,
          Program: {
            ...prevState.Program,
            employeeList: list,
          },
        }))
  
        decrementLoading(store)
      })
      .catch(error => {
        decrementLoading(store)
        throw error
      })
  }
