import UpgradesService from './upgrades.service'

const upgradesService = UpgradesService()

export const reset = store => {
  store.setState(prevState => ({
    ...prevState,
    Upgrades: {
      ...prevState.Upgrades,
      currentTab: 'new-upgrades',
      membershipsForUser: [],
      activeMembersCount: 0,
      pastUpgrades: [],
      totalItems: 0,
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    Upgrades: {...prevState.Upgrades, currentTab: tabName},
  }))
}

export const getMembershipsForUser = async store => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const membershipsForUser = await upgradesService.getMembershipsForUser()
    store.setState(prevState => ({
      ...prevState,
      Upgrades: {
        ...prevState.Upgrades,
        membershipsForUser,
      },
       Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getTargetMembershipAndBillingPlan = async (store, membershipId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const targetMembershipBillingPlan = await upgradesService.getTargetMembershipAndBillingPlan(membershipId)
    store.setState(prevState => ({
      ...prevState,
      Upgrades: {
        ...prevState.Upgrades,
        targetMembershipBillingPlan,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getActiveMembersCount = async (store, billingPlanId) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  incrementLoading(store)
  try {
    const activeMembersCount = await upgradesService.getActiveMembersCount(
      billingPlanId,
    )
    store.setState(prevState => ({
      ...prevState,
      Upgrades: {
        ...prevState.Upgrades,
        activeMembersCount,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const upgradeMembers = async (
  store,
  {
    sourceMembershipId,
    destinationMembershipId,
    sourceMembershipBillPlanId,
    destinationMembershipBillPlanId,
    scheduleId
  },
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await upgradesService.upgradeMembers(
      sourceMembershipId,
      destinationMembershipId,
      sourceMembershipBillPlanId,
      destinationMembershipBillPlanId,
      scheduleId
    )
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: 'Upgrade process has been started!',
    })

    reset(store)
    setCurrentTab(store, 'past-upgrades')
  } catch {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Upgrade process has failed. Please try again',
    })
  }
}

export const getPastUpgrades = async (
  store,
  {triggerGlobalLoading, pageNumber, pageSize},
) => {
  const state = store.state
  const {incrementLoading, decrementLoading} = state.app.actions

  if (triggerGlobalLoading) incrementLoading(store)
  try {
    const {items, count} = await upgradesService.getPastUpgrades(
      pageNumber,
      pageSize,
    )
    store.setState(prevState => ({
      ...prevState,
      Upgrades: {
        ...prevState.Upgrades,
        pastUpgrades: items,
        totalItems: count,
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } finally {
    if (triggerGlobalLoading) decrementLoading(store)
  }
}

export const getSinglePastUpgrade = async (store, link) => {
  return await upgradesService.getSinglePastUpgrade(link)
}
