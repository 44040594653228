import * as queryString from 'query-string'
import * as flatten from 'flat'

import HttpService from '../../services/http.service'

const membershipServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/membership`
const reportsServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/reporting`

const httpService = HttpService()

const DashboardService = () => ({
  getDashboardDetails: async filters => {
    console.log(filters)
    
    const { data } = await httpService.post(
      `${reportsServiceEndpoint}/dashboardDetailCount`,filters,
    )
    return data
  },

  getMembershipsByProgram: async (programId, isReport) => {
    if(programId)
    {
    var reportQuery = isReport == true ? `&isReport=${isReport}` : '';
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/GetMembershipByProgram?programId=${programId}${reportQuery}`,
    )

    return data
    }
  },
  getActiveMembershipsByProgram: async programId => {
    if(programId)
    {
    const {data} = await httpService.get(
      `${membershipServiceEndpoint}/GetActiveMembershipByProgram?programId=${programId}`,
    )

    return data
    }
  }
})


export default DashboardService
