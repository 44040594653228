import NetworkService from './network.service'

const networkService = NetworkService()

export const getNetworkList = async store => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const list = await networkService.getNetworkList()
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      Network: {
        ...prevState.Network,
        networks: list,
        currentTab: 'view',
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const getNetworkToViewPrograms = async (store, id) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const network = await networkService.getNetworkToViewPrograms(id)
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      Network: {
        ...prevState.Network,
        networkToViewPrograms: network,
      },
    }))
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const clearNetworkToView = store => {
  store.setState(prevState => ({
    ...prevState,
    Network: {
      ...prevState.Network,
      networkToViewPrograms: undefined,
    },
  }))
}

export const createNetwork = async (store, networkToCreate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await networkService.createNetwork(networkToCreate)
    decrementLoading(store)
    getNetworkList(store).then(() => {
      showNotification({
        type: 'success',
        message: `Network ${networkToCreate.name} created successfully!`,
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Network creation failed!',
    })
    throw error
  }
}

export const updateNetwork = async (store, networkToUpdate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await networkService.updateNetwork(networkToUpdate)
    clearNetworkToEdit(store);

    decrementLoading(store)
    getNetworkList(store).then(() => {
      showNotification({
        type: 'success',
        message: `Network ${networkToUpdate.name} updated successfully!`,
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Network update failed!',
    })
    throw error
  }
}

export const selectNetworkToEdit = (store, networkToEditId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  networkService
    .getNetworkForUpdate(networkToEditId)
    .then(networkToEdit => {
      store.setState(prevState => ({
        ...prevState,
        Network: {
          ...prevState.Network,
          networkToEdit,
          currentTab: 'createOrEdit',
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)

      throw error
    })
}

export const clearNetworkToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    Network: {
      ...prevState.Network,
      networkToEdit: undefined,
      currentTab: 'view',
    },
  }))
}

export const setCurrentTab = (store, tabName) => {
  if (tabName === 'view') {
    clearNetworkToEdit(store)
  } else {
    store.setState(prevState => ({
      ...prevState,
      Network: { ...prevState.Network, currentTab: tabName },
    }))
  }
}
