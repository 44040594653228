import HttpService from '../../services/http.service'
const supportRequestEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/support`

const httpService = HttpService()

const SupportService = () => ({
    createSupportRequest: async (supportRequest) => {
        const { data } = await httpService.post(
          `${supportRequestEndpoint}/submit`,
          supportRequest,
        )
    
        return data
      }
})
export default SupportService
