import ReservationsService from '../reservations.service'

const reservationService = ReservationsService()

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    NonMemberDetails: { ...prevState.NonMemberDetails, currentTab: tabName },
  }))
}

export const setReservationToEdit = (store, { userId, programId, id, isPast }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  return reservationService
    .getReservation(userId, programId, id)
    .then(reservationToEdit =>
      store.setState(prevState => ({
        ...prevState,
        NonMemberDetails: {
          ...prevState.NonMemberDetails,
          reservationToEdit: { ...reservationToEdit.reservation, isPast },
        },
      })),
    )
    .catch(
      err =>
        err.response?.status === 403 &&
        showNotification({
          type: 'error',
          message:
            'Unauthorized to view this reservation due to lack of course permissions',
        }),
    )
    .finally(() => decrementLoading(store))
}

export const clearReservationToEdit = store => {
  store.setState(prevState => ({
    ...prevState,
    NonMemberDetails: {
      ...prevState.NonMemberDetails,
      reservationToEdit: undefined,
      responseMessage: {}
    },
  }))
}
export const chargeNoShow = async (
  store,
  noShowChargeModel
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  let message, type, error
  try {
    var response = await reservationService.chargeNoShow(noShowChargeModel)
    if (response.status && response.status == 1) {
      type = "success"
      message = "No show charged successfully"
    } else {
      if (response.error !== undefined) {
        message = response.error
        type = "error"
      } else {
        type = "error"
        message = 'An error occurred while No Show charge'
      }
    }
    await showNotification({
      type: type,
      message: message,
    })

    setTimeout(async () => {
      setReservationToEdit(store, { userId: noShowChargeModel.noShowChargePayment.userId, programId: noShowChargeModel.noShowChargePayment.programId, id: noShowChargeModel.noShowChargePayment.bookingId, isPast: noShowChargeModel.noShowChargePayment.isPast })
    }, 1000);

  } catch (err) {
    error = err;
    type = "error"
    if (error.response !== undefined && error.response.data !== undefined) {
      message = error.response.data
    } else {
      message = 'An error occurred while No Show charge'
    }
    await showNotification({
      type: 'error',
      message: message,
    })
    const errorMessage = error.response?.data;
    store.setState(prevState => ({
      ...prevState,
      NonMemberDetails: {
        ...prevState.NonMemberDetails,
        responseMessage: {
          errorMessage
        },
      },
    }));
  }
  finally {
    decrementLoading(store)
  }
}

export const deleteTestCreditCard = async (
  store,
  { memberId, creditCardId }
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await reservationService.deleteTestCreditCard(memberId, creditCardId)
    return true
  } catch (error) {
    store.setState(prevState => ({
      ...prevState,
      NonMemberDetails: {
        ...prevState.NonMemberDetails,
        creditCardDeleteError: error.response.data,
      },
    }))
    return false
  } finally {
    decrementLoading(store)
  }
}

export const clearCreditCardDeleteError = async (store) => {
  store.setState(prevState => ({
    ...prevState,
    NonMemberDetails: {
      ...prevState.NonMemberDetails,
      creditCardDeleteError: undefined,
    },
  }))
  return true
}

export const cancelReservation = async (
  store,
  { userId, reservationId, sgCourseId, programId },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = state.Notification.actions.showNotification
  incrementLoading(store)
  try {
    await reservationService.cancelBooking({
      reservationId: reservationId,
      userId: userId,
      sgCourseId,
      programId
    })
    clearReservationToEdit(store)
  }
  catch (error) {
    let msg = error?.response ? error.response?.data?.split(".")[1].split(":")[2].replaceAll('"', "") : 'The cancel operation failed with an unexpected error!'
    msg = msg.replaceAll("}", "")
    showNotification({
      type: 'error',
      message: msg
    })
  } finally {
    decrementLoading(store)
  }
}

export const cancelAndRefundReservation = async (
  store,
  { userId, reservationId, sgCourseId, programId, isDeposit, isBookingFee, isAdvanceReservationFee, isAmountRefund },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    //SMP-3946 
    if (isAmountRefund) {
      await reservationService.cancelAndRefundBooking({
        reservationId: reservationId,
        userId: userId,
        sgCourseId,
        programId,
        isDeposit,
        isBookingFee,
        isAdvanceReservationFee
      })
    }
    else {
      await reservationService.cancelBooking({
        reservationId: reservationId,
        userId: userId,
        sgCourseId,
        programId
      })
    }
    decrementLoading(store)
    return true
  } catch (error) {
    let msg = error?.response ? error.response?.data?.split(".")[1].split(":")[2].replaceAll('"', "") : 'The refund operation failed with an unexpected error!'
    msg = msg.replaceAll("}", "")
    showNotification({
      type: 'error',
      message: msg,
    })
    decrementLoading(store)
    return false
  }
}

export const getReservations = async (store, { userId, isMember, programId,
  activeReservationsPageNumber,
  activeReservationsPageSize,
  pastReservationsPageSize,
  pastReservationsPageNumber }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    let upcomingResponse = []
    let pastResponse = []
    if (isMember) {
      upcomingResponse = await reservationService.getMemberReservations({
        userId,
        status: 'upcoming',
        sort_by: 'tee_off_at',
        order: 'asc',
        page: activeReservationsPageNumber,
        per_page: activeReservationsPageSize
      })
      pastResponse = await reservationService.getMemberReservations({
        userId,
        status: 'past',
        sort_by: 'tee_off_at',
        order: 'desc',
        page: pastReservationsPageNumber,
        per_page: pastReservationsPageSize
      })
    } else {
      upcomingResponse = await reservationService.getNonMemberReservations(
        programId,
        userId,
        {
          status: 'upcoming',
          sort_by: 'tee_off_at',
          order: 'asc',
          page: activeReservationsPageNumber,
          per_page: activeReservationsPageSize
        },
      )
      pastResponse = await reservationService.getNonMemberReservations(
        programId,
        userId,
        {
          status: 'past',
          sort_by: 'tee_off_at',
          order: 'desc',
          page: pastReservationsPageNumber,
          per_page: pastReservationsPageSize
        },
      )
    }

    const activeReservations = upcomingResponse.reservations

    const pastReservations = pastResponse.reservations

    store.setState(prevState => ({
      ...prevState,
      NonMemberDetails: {
        ...prevState.NonMemberDetails,
        reservation: {
          ...prevState.NonMemberDetails.reservation,
          activeReservations,
          pastReservations,
          activeReservationsTotalItems: upcomingResponse.totalCount ? upcomingResponse.totalCount : 0,
          pastReservationsTotalItems: pastResponse.totalCount ? pastResponse.totalCount : 0,
        },
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const getUserInformation = async (store, { userId, programId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const userInformation = await reservationService.getUserInformation(
      userId,
      programId,
    )
    store.setState(prevState => ({
      ...prevState,
      NonMemberDetails: {
        ...prevState.NonMemberDetails,
        userInformation,
      },
    }))
  } finally {
    decrementLoading(store)
  }
}

export const updateUserInformation = async (
  store,
  { userId, programId, newUserInformation },
) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  const showNotification = store.state.Notification.actions.showNotification

  incrementLoading(store)
  try {
    await reservationService.updateUserInformation(
      userId,
      programId,
      newUserInformation,
    )
    await getUserInformation(store, { userId, programId })
    await showNotification({
      type: 'success',
      message: 'User successfully updated',
    })
  } catch (error) {
    let msg
    if (error.response.data !== undefined && error.response.data.indexOf('Email is already in use.') >= 0) {
      msg = error.response.data
    } else {
      msg = 'An error occurred while trying to update user information'
    }
    await showNotification({
      type: 'error',
      message: msg,
    })
  } finally {
    decrementLoading(store)
  }
}

export const clearNonMemberDetails = store => {
  store.setState(prevState => ({
    ...prevState,
    NonMemberDetails: {
      ...prevState.NonMemberDetails,
      currentTab: 'bookings',
      reservationToEdit: undefined,
      reservation: {
        activeReservations: [],
        pastReservations: [],
        activeReservationsTotalItems: 0,
        pastReservationsTotalItems: 0,
      },
      userInformation: undefined,
    },
  }))
}

export const modifyBooking = async (store, { programId, reservationId, newQty, userId, previousQty, isDeposit,
  isBookingFee,
  isAdvanceReservationFee, creditCardId }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await reservationService.modifyBooking(programId, reservationId, newQty, userId, previousQty, isDeposit,
      isBookingFee,
      isAdvanceReservationFee, creditCardId)
    clearReservationToEdit(store)
  }
  finally {
    decrementLoading(store)
  }
}

export const getCheckoutData = async (store, { programId, reservationId, newQty, userId, isDeposit, isBookingFee, isAdvanceReservationFee }) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    if (isDeposit != undefined) {
      return await reservationService.getSelectedRefundCheckoutData(programId, reservationId, newQty, isDeposit, isBookingFee, isAdvanceReservationFee)
    }
    else {
      const checkoutData = await reservationService.getCheckoutData(programId, reservationId, newQty)
      store.setState(prevState => ({
        ...prevState,
        CheckoutData: {
          ...prevState.CheckoutData,
          checkoutData: checkoutData
        },
      }))
      return checkoutData.update_reservation
    }
  } finally {
    decrementLoading(store)
  }
}
