import React, {Component} from 'react'

export default class ErrorBoundary extends Component {
  constructor() {
    super()

    this.state = {
      hasErrored: false,
    }
  }
  static getDerivedStateFromError(error) {
    // process the error
    return {hasErrored: true}
  }

  componentDidCatch(error, info) {
    // we might want a service sending error report to log endpoint
    console.log(error)
  }

  render() {
    if (this.state.hasErrored) {
      // use custom error
      return <div> Something went wrong</div>
    }

    return this.props.children
  }
}
