import PaymentsService from './payments.service'

const paymentsService = PaymentsService()

export const setCurrentTab = (store, tabName) => {
  store.setState(prevState => ({
    ...prevState,
    Payments: { ...prevState.Payments, currentTab: tabName },
  }))
}

export const setProgramConfiguration = (store, programConfiguration) => {
  store.setState(prevState => ({
    ...prevState,
    Payments: { ...prevState.Payments, programConfiguration: programConfiguration, },
  }))
}
export const getPaymentProcessors = store => {
  const state = store.state

  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  return paymentsService
    .getPaymentProcessors()
    .then(list => {
      store.setState(prevState => ({
        ...prevState,
        Payments: {
          ...prevState.Payments,
          paymentProcessors: {
            legacyPaymentProcessors: [...list.legacyPaymentProcessors],
            defaultPaymentProcessors: [...list.defaultPaymentProcessors]
          },
          Member:{
            ...prevState.Member,
            email: '',
          }
        },
      }))

      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const getPaymentProcessorConfiguration = (store, currentGlobalProgramId) => {
  const state = store.state

  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  return paymentsService
    .getPaymentProcessorConfiguration(currentGlobalProgramId)
    .then(configuration => {
      store.setState(prevState => ({
        ...prevState,
        Payments: {
          ...prevState.Payments,
          programConfiguration: configuration,
        },
      }))

      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)
      throw error
    })
}

export const postPaymentProcessorConfiguration = async (store, { currentGlobalProgramId, requestConfiguration }) => {
  const state = store.state

  const showNotification = state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  try {
    await paymentsService.postPaymentProcessorConfiguration(currentGlobalProgramId, requestConfiguration)

    showNotification({
      type: 'success',
      message: 'Payment Processor saved successfully',
    })
    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Payment Processor save failed!',
    })
    throw error
  }
}


export const deletePaymentProcessorConfiguration = async (store, { currentGlobalProgramId, legacyProcessorConfigToDelete }) => {
  const state = store.state

  const showNotification = state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)

  try {
    await paymentsService.deletePaymentProcessorConfiguration(currentGlobalProgramId, legacyProcessorConfigToDelete)

    showNotification({
      type: 'success',
      message: 'Payment Processor saved successfully',
    })
    decrementLoading(store)
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Payment Processor save failed!',
    })
    throw error
  }
}