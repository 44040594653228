import * as queryString from 'query-string'

import HttpService from '../../services/http.service'

const bookingServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/reservation`

const httpService = HttpService()

const BookingService = () => ({
  getBookings: async (query, options) => {
    const {data} = await httpService.get(
      `${bookingServiceEndpoint}?${queryString.stringify(query)}`,
      options,
    )

    return data
  },
})

export default BookingService
