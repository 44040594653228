import MessageService from './message.service'

const messageService = MessageService()


export const getMessageList = async store => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  try {
    const list = await messageService.getMessageList()
    decrementLoading(store)
    store.setState(prevState => ({
      ...prevState,
      Message: {
        ...prevState.Message,
        messages: list,
        currentTab: 'view',
      },
      Member:{
        ...prevState.Member,
        email: '',
      }
    }))
  } catch (e) {
    decrementLoading(store)
    throw e
  }
}

export const createMessage = async (store, messageToCreate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await messageService.createMessage(messageToCreate)
    decrementLoading(store)
    showNotification({
      type: 'success',
      message: `Message created successfully!`,
    })
    // getMessageList(store).then(() => {
      
    // })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Message creation failed!',
    })
    throw error
  }
}

export const updateMessage = async (store, messageToUpdate) => {
  const state = store.state
  const showNotification = store.state.Notification.actions.showNotification
  const { incrementLoading, decrementLoading } = state.app.actions
  incrementLoading(store)
  try {
    await messageService.updateMessage(messageToUpdate)
    clearMessageToEdit(store);

    decrementLoading(store)
    getMessageList(store).then(() => {
      showNotification({
        type: 'success',
        message: `Message  updated successfully!`,
      })
    })
  } catch (error) {
    decrementLoading(store)
    showNotification({
      type: 'error',
      message: 'Message update failed!',
    })
    throw error
  }
}


  export const getSGCoursesByProgramID =async (store,programId) => {
    const state = store.state
    const { incrementLoading, decrementLoading } = state.app.actions
   
    incrementLoading(store)
    try {
      const list = await messageService.getSGCoursesByProgramID(programId)  
     
      decrementLoading(store)
      store.setState(prevState => ({
        ...prevState,
        Message: {
          ...prevState.Message,
          currentSGCourses: list,
        },
      }))
    } catch (e) {
      store.setState(prevState => ({
        ...prevState,
        Message: {
          ...prevState.Message,
          currentSGCourses: [],
        },
      }))
      decrementLoading(store)
      throw e
    }

  }

  
export const selectMessageToEdit = (store, messageToEditId) => {
  const state = store.state
  const { incrementLoading, decrementLoading } = state.app.actions

  incrementLoading(store)
  messageService
    .getMessageForUpdate(messageToEditId)
    .then(messageToEdit => {
      store.setState(prevState => ({
        ...prevState,
        Message: {
          ...prevState.Message,
          messageToEdit,
          currentTab: 'createOrEdit',
        },
      }))
      decrementLoading(store)
    })
    .catch(error => {
      decrementLoading(store)

      throw error
    })
}

  export const getMessageToViewCoursePolicies = async (store, id) => {
    
    const state = store.state
    const { incrementLoading, decrementLoading } = state.app.actions
  
    incrementLoading(store)
    try {
      const messageViewCoursePolicies = await messageService.getMessageToViewCoursePolicies(id)
      decrementLoading(store)
      store.setState(prevState => ({
        ...prevState,
        Message: {
          ...prevState.Message,
          messageToViewCoursePolicies: messageViewCoursePolicies,
        },
      }))
    } catch (e) {
      decrementLoading(store)
      throw e
    }
  }

    

  export const clearMessageToView = store => {
    store.setState(prevState => ({
      ...prevState,
      Message: {
        ...prevState.Message,
        messageToViewCoursePolicies: undefined,
      },
    }))
  }

export const clearMessageToEdit = store => {
    store.setState(prevState => ({
      ...prevState,
      Message: {
        ...prevState.Message,
        messageToEdit: undefined,
        currentTab: 'view',
      },
    }))
  }

  export const selectMessageToCancelEdit = (store, messageToEditId) => {
    store.setState(prevState => ({
    ...prevState,
    Message: {
    ...prevState.Message,
    messageToEdit: undefined,
    currentTab: 'view',
    },
    }))
    }

export const setCurrentTab = (store, tabName) => {
    if (tabName === 'view') {
        clearMessageToEdit(store)
    } else {
      store.setState(prevState => ({
        ...prevState,
        Message: { ...prevState.Message, currentTab: tabName },
      }))
    }
  }