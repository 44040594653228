import {createActions} from '../../context'
import * as actions from './manageUsers.actions'

export const createManageUsers = store => {
  return {
    users: {items: [], count: 0},
    userToEdit: undefined,
    currentTab: 'view',
    subRolesForCreateOrEdit: [],
    actions: createActions(actions)(store),
  }
}
