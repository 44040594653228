import HttpService from '../../services/http.service'

const messageServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/message`

const programServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/program`

const courseServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/course`

const httpService = HttpService()

const MessageService = () => ({
  getMessageList: async () => {
    const { data } = await httpService.get(
      `${messageServiceEndpoint}/list?filter.page.pageNumber=1&filter.page.pageSize=10`,
    )
    return (
      data.items &&
      data.items.map(p => ({ ...p, created_Date: new Date(p.created_Date) }))
    )
  },

  getMessageForUpdate: async messageId => {
    const { data } = await httpService.get(
      `${messageServiceEndpoint}/${messageId}`,
    )

    return data
  },

  createMessage: async messageToCreate => {
    const { data } = await httpService.post(
      `${messageServiceEndpoint}`,
      messageToCreate,
    )

    return data
  },

  updateMessage: async messageToUpdate => {
    const { data } = await httpService.put(
      `${messageServiceEndpoint}/${messageToUpdate.id}`,
      messageToUpdate,
    )

    return data
  },
  

  getSGCoursesByProgramID: async (programId) => {   
    const {data} = await httpService.get(
      `${courseServiceEndpoint}/courses-from-slug/${programId}`)
    return data   
  },


  getMessageToViewCoursePolicies: async id => {
    const { data } = await httpService.get(`${messageServiceEndpoint}/${id}/view`)
    return data
  },
  getMessageToCheckProgramExists: async id => {
    const { data } = await httpService.get(`${messageServiceEndpoint}/${id}/CheckProgramExists`)
    return data
  },
})

export default MessageService
  