import HttpService from '../../services/http.service'
import { saveAs } from 'file-saver';

const migrationServiceEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/migration`
const httpService = HttpService()

const MigrationService = () => ({
    getMigrationList: async programId => {
        const {data} = await httpService.get(
            `${migrationServiceEndpoint}/list`,
            {
                params: {
                    programId: programId
                }
            }
          )
          return data && data.map(p => ({...p, created_Date : new Date(p.created_Date), resultLink : `${migrationServiceEndpoint}/file/${p.id}`}))
    },
    postMigration: async migrationToCreate => {
        const formData = new FormData();
        const {migrationFile, programId} = migrationToCreate;

        formData.append('', JSON.stringify({programId}));
        formData.append('migrationFile', migrationFile);

        const {data} = await httpService.post(
            migrationServiceEndpoint,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        )
        return data;
    },
    downloadFile: async (link) =>
    {
        const response = await httpService.get(link, {responseType: 'blob'});
        const {data, headers} = response;
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = headers[fileNameHeader];
        const effectiveFileName = (suggestedFileName === undefined
                    ? "result.csv"
                    : suggestedFileName);
        saveAs(data, effectiveFileName);
    }
})

export default MigrationService