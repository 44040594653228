export const showNotification = (store, {type = '', message = ''}) => {
  store.setState(prevState => ({
    ...prevState,
    Notification: {...prevState.Notification, message, type, isShow: true},
  }))
}

export const hideNotification = store => {
  store.setState(prevState => ({
    ...prevState,
    Notification: {
      ...prevState.Notification,
      message: '',
      type: '',
      isShow: false,
    },
  }))
}
