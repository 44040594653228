import {createActions} from '../../context'
import * as actions from './upgrades.actions'

export const createUpgrades = store => {
  return {
    currentTab: 'past-upgrades',
    upgradesInfo: [],
    activeMembersCount: 0,
    pastUpgrades: [],
    totalItems: 0,
    targetMembershipBillingPlan: [],
    actions: createActions(actions)(store),
  }
}
